import React, { useEffect, useState  } from 'react';
import { useSearchParams} from 'react-router-dom';
import MainCard from 'components/MainCard';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MultiSelect from 'components/Form/MultiSelect';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
    Accordion, AccordionDetails, AccordionSummary, Typography, Divider, Stack, List, ListItem,
    LinearProgress, FormControlLabel, FormControl, InputLabel, MenuItem, Select,
    Grid, Badge, TextField, Checkbox, IconButton, Autocomplete, Button
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useSelector, useDispatch } from 'react-redux';
import SearchEquipment from 'containers/SearchViews/SearchEquipment'
import StyledDataGrid from 'components/DataGrid/index';
import Link from '@mui/material/Link';
import moment from 'moment'
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { getAllActiveCompanyAction } from 'containers/Management/Companies/state/actions';
import {
    getRegionByIdAction, reSubmitAction, formDocumentAction, submitDRAction, decisionAction, cancelAction,
    downloadDocAction, deleteDocAction, searchContactAction, completeAction, updateAction,
    formInitialAction, formDetailAction
} from './state/actions';

import AlertModal from 'components/AlertModal';
import DynamicTable from './DynamicTable'
import DeleteModal from 'components/DeleteModal';
import FormIntial from '../FormIntial'
import ConfirmationPage from '../ConfirmationPage'
import { CircularProgress } from '@mui/material/index';
import { actions } from '../../../store/Reducer/actions';

const AccordionSummaryRev = styled(AccordionSummary)({
    flexDirection: 'row-reverse',
    alignItems: "start",
    '& > div': { margin: '0 !important' }
});
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function DisposalRequestPrint({ setView, needBack = false, drId }) {
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');
    const [historyExpanded, setHistoryExpanded] = React.useState(true);
    const { decodedToken } = useSelector((state) => state.token);
    const { companyLists = [] } = useSelector((state) => state.company);
    const { regionData = {}, formUploadData = {}, isDocLoading, docUrl, isDocDownloaded,
        isDeleted, contactList = [], isContactLoading, PkForm, showNotification, detailData, isDetailLoading,
        initialData = {}, isFormInitialLoading, decisionData, isSubmited } = useSelector((state) => state.businessProcess);
    const [company, setCompany] = useState('');
    const [isTpm, setIsTpm] = useState(false);
    const [isContactShow, setContactShow] = useState(true);
    const [contactValue, setContactValue] = useState({});
    const [selectedNameList, setSelectedNameList] = useState([]);
    const [selectedName, setSelectedName] = useState([]);
    const [finalNameList, setfinalNameList] = useState([]);
    const [selectedContact, setSelectedContact] = useState({});
    const [openAlertModal, toggleAlertModal] = useState({});
    const [files, setFiles] = useState('');
    const [FormId, setFormId] = useState('');
    const [pageView, setPageView] = useState('newDisposal');
    const [openDeleteModal, toggleDeleteModal] = useState({});
    const [decision, setDecision] = useState('');
    const [isEdit, setIsEdit] = useState(true);
    const [drData, setDrData] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const [formDisabled, setFormDisabled] = useState(false);
    const [notificationAction, setNotificationAction] = useState('');
    const [regionName, setRegionName] = useState('');
    const [isDisableDrRule, setIsDisableDrRule] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deleteDocId, setDeleteDocId] = useState('');
    const [samComment, setSamComment] = useState('');
    const { roles } = useSelector((state) => state.token);
    const { currencies = [], currencyLoading } = useSelector((state) => state.currency);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [DRHeaderList, setFromDRHeaderList] = useState([]);
    const [DRFormPrintList, setDRFormPrintList] = useState([]);
    const [DRFormPrint5, setDRFormPrintCount5] = useState(false);
    const [DRFormPrint10, setDRFormPrintCount10] = useState(false);
    const [DRFormPrint20, setDRFormPrintCount20] = useState(false);
    const [DRFormPrint40, setDRFormPrintCount40] = useState(false);
    const [DRFormPrint60, setDRFormPrintCount60] = useState(false);
    const [DRFormPrint80, setDRFormPrintCount80] = useState(false);
    const { drawerOpen } = useSelector((state) => state.common);
    const [isPrinting, setIsPrinting] = useState(false);
    const drUrlid =  searchParams.get("id")

    const [rowsData, setRowsData] = useState([{
        pkEquipment: '',
        location: '',
        netBookValue: ''
    }]);

    const decisionList = {
        label: "Decision",
        options: [
            { label: 'Select...', value: "" },
            { label: 'Accept', value: "Accept" },
            { label: 'Reject', value: "Reject" },
        ]
    }
    useEffect(() => {
        if (PkForm !== undefined && PkForm !== '') {
            setIsEdit(false)
            setFormId(PkForm)
        }
    }, [PkForm]);

    useEffect(() => {
        if (drId !== undefined && drId !== '') {
            setFormId(drId)
            setIsEdit(false)
        }
    }, [drId]);

    useEffect(() => {
        if (drUrlid !== undefined && drUrlid !== null && drUrlid !== '') {
            console.log("drUrlid 1", drUrlid)
            setFormId(drUrlid)
            setIsEdit(false)
            // dispatch({type:'GET_INITIAL_DATA'})
            dispatch({ type: "CLEAR_DR_DATA" })
            dispatch(formDetailAction(drUrlid))
        }
    }, [drUrlid]);

    useEffect(() => {
        dispatch(getAllActiveCompanyAction())
        dispatch({ type: "CLEAR_EQ_DATA" })
        dispatch({ type: "CLEAR_DR_DATA" })
    }, []);

    useEffect(() => {
        setRegionName(regionData.regionName)
    }, [regionData]);

    useEffect(() => {
        if (FormId === '' && drId === undefined) {
            dispatch(formInitialAction())
        }
    }, []);

    useEffect(() => {
        if (isFormInitialLoading || isDetailLoading)
            setIsPageLoading(true)
        else
            setIsPageLoading(false)
    }, [isFormInitialLoading, isDetailLoading]);

    useEffect(() => {
        if (isDeleted) {
            let tmpList = documentList.filter((item) => item.pkFormDocument !== deleteDocId)
            setDocumentList([...tmpList])
        }
    }, [isDeleted]);

    useEffect(() => {
        if (formUploadData?.pkFormDocument !== undefined) {
            if (documentList.length > 0) {
                let temp = documentList.find(({ pkFormDocument }) => pkFormDocument === formUploadData.pkFormDocument);
                if (temp === undefined)
                    setDocumentList([...documentList, formUploadData])
            }
            else
                setDocumentList([formUploadData])
        }
    }, [formUploadData?.pkFormDocument]);

    const onCompanyChange = (e, value) => {
        setCompany(value);
        let getSelectedRow = companyLists.find((item) => item.pkCompany === value.id)
        setIsTpm(getSelectedRow.isTpm)
        dispatch(getRegionByIdAction(getSelectedRow.fkRegion));
    }

    const onDecisionChange = ({ target }) => {
        setDecision(target.value)
        setIsDisableDrRule(false)
    }

    const validateSubmit = (type) => {
        let message = ''
        if (company === '') {
            setIsError(true)
            message = `${message}
            <br/>- Requestor company is mandatory`
        }
        if (finalNameList.length === 0) {
            setIsError(true)
            message = `${message}
            <br/>- Contact(s)  is mandatory`
        }
        rowsData.forEach((asd) => {
            const { pkEquipment, netBookValue, p1idNumber, condition, netBookValueValidity, availability,
                drRule, drDecisionDate, drFinalStatus } = asd;

            if (pkEquipment === '') {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment existing SAM ID is mandatory`
            }
            if (p1idNumber === undefined || p1idNumber === null || p1idNumber === '') {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment SAP/PI Ref is mandatory`
            }
            if (!condition || !condition?.id) {
                setIsError(true)
                message = `${message}
                <br/>- Please select other condition`
            }
            if (netBookValue === undefined || netBookValue === null || netBookValue === '' || netBookValue < 0) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment Net Book Value is mandatory`
            }
            if (!netBookValueValidity) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment Net Book Value Validity date is mandatory`
            }
            if (!availability) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment  Availability Date is mandatory`
            }
            if ((decision === 'Accept' || type === 'update') && (drRule === null || drRule === 'No rule')) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment DR rule is mandatory for SAM ID ${pkEquipment}.`
            }
            if ((decision === 'Accept' || type === 'update') && (drDecisionDate === null || drDecisionDate === '')) {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment Decision Date is mandatory for SAM ID ${pkEquipment}.`
            }
            if (type === 'complete' && (drFinalStatus === null || drFinalStatus === '') && drRule !== 'Cancel') {
                setIsError(true)
                message = `${message}
                <br/>- Requested equipment must have a Final DR Status for SAM ID ${pkEquipment}.`
            }
        })
        if (!comment && (type === 'submit' || type === 'resubmit')) {
            setIsError(true)
            message = `${message}\r\n
            <br/>- Justification / Information / Message is mandatory`
        }
        if (type === 'decision' || type === 'update' || type === 'complete') {
            if (decision === '') {
                setIsError(true)
                message = `${message}
                <br/>- Decision is mandatory.`
            }
        }
        if (decision === 'Reject' && samComment === '') {
            setIsError(true)
            message = `${message}
            <br/>- SAM comment is mandatory.`
        }
        return message
    }
    const handleSubmit = () => {
        let message = validateSubmit('submit')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                let tmpCurrency = ''
                if (asd.fkNetBookValueCurrency === '')
                    tmpCurrency = 'USD'
                else if (asd.fkNetBookValueCurrency?.id === null)
                    tmpCurrency = 'USD'
                else
                    tmpCurrency = asd.fkNetBookValueCurrency?.id
                return {
                    pkEquipment: asd.pkEquipment,
                    sapP1Ref: asd.p1idNumber,
                    fkCondition: asd.condition?.id,
                    inSilentStoreSince: asd.inSilentStoreSince === null ? null : asd.inSilentStoreSince,
                    netBookValue: asd.netBookValue,
                    fkCurrency: tmpCurrency,
                    netBookValueValidity: asd.netBookValueValidity,
                    availability: asd.availability,
                    scrapImmediately: asd.scrapImmediately || false,
                    drRule: "",
                    recoverParts: false,
                    drDecision: "",
                    drFinalStatus: "",
                    equipmentComment: asd.equipmentComment
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "stepId": 0,
                "stateId": 0,
                "formType": "DR",
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "disposalEquipmentDTOs": eqDTO,
                "documentDTOs": [...docList],
                "contactSid": sidList.length > 0 ? [...sidList] : [],
            }
            dispatch(submitDRAction(dd))
            setNotificationAction('submit')
        }
    };

    const handleReSubmit = () => {
        let message = validateSubmit('resubmit')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                let tmpCurrency = ''
                if (asd.fkNetBookValueCurrency === '')
                    tmpCurrency = 'USD'
                else if (asd.fkNetBookValueCurrency?.id === null)
                    tmpCurrency = 'USD'
                else
                    tmpCurrency = asd.fkNetBookValueCurrency?.id
                return {
                    pkEquipment: asd.pkEquipment,
                    sapP1Ref: asd.p1idNumber,
                    fkCondition: asd.condition?.id,
                    inSilentStoreSince: asd.inSilentStoreSince === null ? null : asd.inSilentStoreSince,
                    netBookValue: asd.netBookValue,
                    fkCurrency: tmpCurrency,
                    netBookValueValidity: asd.netBookValueValidity,
                    availability: asd.availability,
                    scrapImmediately: asd.scrapImmediately || false,
                    drRule: asd.drRule,
                    recoverParts: asd.recoverParts,
                    RecoverPart: asd.recoverParts,
                    drDecision: asd.drDecision,
                    drFinalStatus: "",
                    equipmentComment: asd.equipmentComment
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "disposalEquipmentDTOs": eqDTO,
                "step10Decision": "",
                "step10DecisionComment": samComment,
                "formDocuments": [...docList],
                "contactSid": sidList.length > 0 ? [...sidList] : [],
            }

            dispatch(reSubmitAction(dd))
            setNotificationAction('resubmit')
        }
    };

    const handleFileUpload = ({ target }) => {
        let name = target.files[0].name
        let filesize = target.files[0].size
        let filesizeKb = filesize / 1024

        let lastIndx = name.lastIndexOf('.')
        let fileExt = name.substring(lastIndx + 1, name.length)
        let acceptableFiles = ['doc', 'docx', 'xlsx', 'pdf', 'msg', 'txt', 'jpg', 'jpeg']
        let showAlert = false
        let message = ''
        if (filesizeKb > 4096) {
            showAlert = true
            message = `Max file size (Kb) :4096`
        }
        if (!acceptableFiles.includes(fileExt)) {
            showAlert = true
            if (message !== '')
                message = `${message} <br/> Only file of type: MS Word(.doc / .docx); MS Excel(.xlsx);
                Adobe PDF(.pdf); MS Outlook Message(.msg); text file(.txt) and Picture(.jpg /.jpeg) are authorized`
            else
                message = `Only file of type: MS Word(.doc / .docx); MS Excel(.xlsx);
           Adobe PDF(.pdf); MS Outlook Message(.msg); text file(.txt) and Picture(.jpg /.jpeg) are authorized`
        }
        if (showAlert)
            toggleAlertModal({ open: true, message: message });
        else {
            const reader = new FileReader()
            reader.readAsBinaryString(target.files[0])
            reader.onloadend = () => {
                setFiles(target.files[0])
            }
        }
    }

    const toggleTPM = (evnt) => {
        setIsTpm(evnt.target.checked ? true : false)
    }

    const saveFiles = () => {
        if (files === '')
            toggleAlertModal({ open: true, message: 'Please upload file.' });
        else {
            let fd = new FormData
            fd.append('Source', 'step1Attachement')
            fd.append('Session', 0)
            fd.append('file', files)
            dispatch(formDocumentAction(fd, FormId))
            setFiles('')
        }
    }

    useEffect(() => {
        return () => {
            dispatch({ type: 'DOWNLOAD_DOC_FAILED' });
        }
    }, []);


    const handlePrintClick = () => {
        // setMrfId(mrfId);
        // dispatch(formMrfAction(mrfId))
        //setPageViews('confirmation');
        var css = '@page { size: landscape; }',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');
    
    style.type = 'text/css';
    style.media = 'print';
    setIsPrinting(true);

    if (style.styleSheet){
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    
    head.appendChild(style);
    
    window.print();
    }


    const downloadDocClick = (row) => {
        if (FormId === '')
            alert("Please submit Disposal request before dowloading document")
        else {
            if (row.documentContent && row.documentContent !== 'MA==') {
                const a = document.createElement("a");
                a.href = `data:application/octet-stream;base64,${row.documentContent}`;
                a.download = row.documentName;
                a.click();
            } else {
                let downloadObj = {
                    docId: row.pkFormDocument,
                    formId: FormId
                }
                dispatch(downloadDocAction(downloadObj))
            }

        }
    };

    const columnc = [
        { field: 'id', headerName: `Sl No`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader",hide:true},
        { field: 'stepname', headerName: `Current Step`, width: 10, sortable: false,flex:0.12,height:60,headerClassName: "dgHeader"},
        { field: 'statename', headerName: `Current State`, width: 6, sortable: false,flex:0.075,height:60,headerClassName: "dgHeader"},
        { field: 'formtype', headerName: `Form Type`, width: 10, sortable: false,flex:0.12  ,height:60,headerClassName: "dgHeader"},        
        { field: 'pkform', headerName: `Form #`, width: 10, sortable: false,flex:0.05,height:80,headerClassName: "dgHeader"},
        { field: 'requestdate', headerName: `Request Date`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader",hide: true },
        { field: 'lastupdatedate', headerName: `Last Update Date`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader",hide: true },
        { field: 'name', headerName: `Requestor Name`, width: 10, sortable: false,flex:0.2,height:60,headerClassName: "dgHeader"},
        { field: 'email', headerName: `Requestor Email`, width: 10, sortable: false,flex:0.2,height:60,headerClassName: "dgHeader"},
        { field: 'alias', headerName: `Requestor Alias`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},        
        { field: 'title', headerName: `Requestor Title`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},
        { field: 'department', headerName: `Requestor Department`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},
        { field: 'country', headerName: `Requestor Country`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"},
        { field: 'company', headerName: `Requestor Company`, width: 10, sortable: false,flex:0.1,height:60,headerClassName: "dgHeader"}                                                                
    ];

    const column = [
        { field: 'id', headerName: `Sl No`, width: 10, sortable: false,flex:0.1,height:80,headerClassName: "dgHeader",hide: true },
        { field: 'pkEquipment', headerName: `SAM ID`, width: 100, height:80,sortable: false,headerClassName: "dgHeader",},
        { field: 'x',  renderHeader: () => (
                <Box>
        <Grid container>
        <Typography variant="body2" component="div" align="center">
          Company
        </Typography>
        <Typography variant="body2" component="div" align="center">
          Location  
        </Typography>
        <Typography variant="body2" component="div" align="center">
          Status
        </Typography>
      </Grid>
                </Box>
          ),headerName:``,sortable: false,height:100, width: 120, headerClassName: "dgHeader", 
        renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.company}`}</Grid>
                <Grid>{`${row.location}`}</Grid>
                <Grid>{`${row.status}`}</Grid>
            </Stack>
        )},
        { field: 'y', headerName: `Area Function OEM Model`, width: 140,height:80, headerClassName: "dgHeader", renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.area}`}{` `}{`${row.functionVal}`}</Grid>
                <Grid>{`${row.oemName}`}{` `}{`${row.modelName}`}</Grid>
            </Stack>
        ) },
        { field: 'z', headerName: `Serial No sapP1Ref`, width: 80, headerClassName: "dgHeader", renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.serialNo}`}</Grid>
                <Grid>{`${row.sapP1Ref}`}</Grid>
            </Stack>
        ) },
        { field: 'a', headerName: `Condition</br>in silent store since`, width: 80, headerClassName: "dgHeader", renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.condition}`}</Grid>
                <Grid>{`${row.availability}`}</Grid>
            </Stack>
        ) },
        { field: 'b', headerName: `NetBookValue</br>validity`, width: 80, headerClassName: "dgHeader", renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                <Grid>{`${row.netBookValue}`}</Grid>
                <Grid>{`${row.fkNetBookValueCurrency}`}</Grid>
                <Grid>{`${row.netBookValueValidity}`}</Grid>
            </Stack>
        ) },
        { field: 'availability', headerName: `Availability`, width: 80, headerClassName: "dgHeader", },
        { field: 'equipmentComment', headerName: `Justification/Comment`, width: 300, headerClassName: "dgHeader", },
        { field: 'c', headerName: `DR Rule`, width: 80, headerClassName: "dgHeader",renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                 <Grid>{`${row.drRule}`}</Grid>
            </Stack>
        ) },
        { field: 'd', headerName: `DR Decision and Date Decision by`, width: 120, headerClassName: "dgHeader",renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                 <Grid>{`${row.drDecision}`}</Grid>
                 <Grid>{`${row.drDecisionBy}`}</Grid>
                 <Grid>{`${row.drDecisionDate}`}</Grid>
            </Stack>
        ) },
        { field: 'e', headerName: `DR Final Status`, width: 80, headerClassName: "dgHeader",renderCell: ({ row }) => (
            <Stack sx={{ mt: 1 }} direction="column" spacing={1}>
                 <Grid>{`${row.drFinalStatus}`}</Grid>
            </Stack>
        ) },
        { field: 'recoverPart', headerName: `Recover Part`, width: 80, headerClassName: "dgHeader", },
        { field: 'retrievePart', headerName: `Retrieve Part`, width: 80, headerClassName: "dgHeader", },
    ];

    const onDeleteClick = (e, data) => {
        toggleDeleteModal({ open: true, data });
    }

    const deleteConfirm = (data) => {
        toggleDeleteModal({});
        setDeleteDocId(data.pkFormDocument)
        dispatch(deleteDocAction(data.pkFormDocument));
    }

    const toggleSearchModal = (isShow) => {
        setContactShow(!isShow)
    }

    const openSearch = () => {
        toggleSearchModal(true)
        setSelectedNameList([])
    }

    const handleSearch = () => {
        if (contactValue.contact === undefined || contactValue.contact === '')
            toggleAlertModal({ open: true, message: 'A value is needed in the search textbox' });
        else {
            setSelectedNameList([])
            dispatch(searchContactAction(contactValue.contact))
        }
    }

    useEffect(() => {
        if (contactList.length > 0) {
            setSelectedNameList([...contactList])
        }
    }, [contactList]);

    useEffect(() => {
        if (showNotification) {
            setPageView('confirmation')
        }
    }, [showNotification]);

    const handleDelete = () => {
        let tmpList = finalNameList.filter((item) => item.value !== selectedContact.value)
        setfinalNameList([...tmpList])
    }

    const handleAdd = () => {
        if (selectedName.length === 0)
            toggleAlertModal({ open: true, message: 'Select a name from the list' });
        else {
            let filterName = [...finalNameList, ...selectedName]
            let newfilterName = filterName.filter((item, index, self) => index === self.findIndex((ele) => ele.value === item.value))
            newfilterName.map((item) => {
                let newFiledName = `isSelected${item.value}`
                item[newFiledName] = false
                return item
            })
            setfinalNameList([...newfilterName])
            setContactShow(true)
        }
    }

    const onContactChange = ({ target }) => {
        setContactValue({ [target.name]: target.value })
    }

    const onNameChange = (name, allSelected) => {
        setSelectedName([...name.name])
    }

    const handleListClick = (selectedItem) => {
        finalNameList.map((item) => {
            if (item.value === selectedItem.value)
                item[`isSelected${item.value}`] = true
            else
                item[`isSelected${item.value}`] = false
            return (item)
        })
        setfinalNameList([...finalNameList])
        setSelectedContact(selectedItem)
    }

    useEffect(() => {
        if (isDocDownloaded) {
            let a = document.createElement('a');
            a.href = docUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }, [isDocDownloaded]);


    let initialInfo = {}
    if (FormId === '' && drId === undefined)
        initialInfo = { formInfo: { ...initialData.formHeader }, requestorInfo: { ...initialData.formRequester } }
    else
        initialInfo = { formInfo: { ...detailData.formHeader }, requestorInfo: { ...detailData.formRequester } }

    useEffect(() => {
        if ((FormId !== '' || drId !== undefined) && !isDetailLoading) {
            if (Object.keys(detailData).length > 0) {
                let role = roles.includes('admin')
                if (!role && detailData.formBody.step10Decision !== 'Reject') {
                    setFormDisabled(true)
                }
                if (detailData.formHeader.stepId === 5)
                    setIsEdit(true)

                if (detailData?.formHeader?.stepName === 'Closed')
                    setFormDisabled(true)
                if (detailData?.formHeader?.stepId === 20)
                    setIsDisableDrRule(false)

                setDrData({ ...detailData })
                if (detailData?.formBody?.formCompanyId !== '') {
                    let getSelectedRow = companyLists.find((item) => item.pkCompany === detailData?.formBody?.formCompanyId)
                    let selectedCompany = { id: detailData?.formBody?.formCompanyId, label: getSelectedRow?.longCompanyName }
                    setCompany(selectedCompany);
                }

                setComment(detailData?.formBody?.comment);
                setRegionName(detailData.formBody.formCompanyRegionName)
                if (detailData?.formBody?.requesterCompanyIsTPM === 1)
                    setIsTpm(true)
                else
                    setIsTpm(false)

                if (detailData.formDocument?.length > 0) {
                    let tmpList = [...detailData.formDocument]
                    tmpList.map((dt, index) => {
                        dt.id = index
                        dt.uploadedByDisplayName = dt.uploadedByDisplayName != undefined ? dt.uploadedByDisplayName : decodedToken.name
                        return dt
                    })
                    setDocumentList([...tmpList])
                }

                if (detailData.formContact?.length > 0) {
                    let tmpArr = []
                    detailData.formContact.forEach((item) => {
                        item.label = item.name
                        item.emailAddress = item.emailAddress !== undefined ? item.emailAddress : item.email
                        item.value = item.alias ? item.alias : item.email
                        let newFiledName = `isSelected${item.value}`
                        item[newFiledName] = false
                        tmpArr.push(item)
                    })
                    setfinalNameList([...tmpArr])
                }
                if (detailData.formBody.step10Decision !== undefined)
                    setDecision(detailData.formBody.step10Decision)
                if (detailData.formBody.step10Comment !== undefined)
                    setSamComment(detailData.formBody.step10Comment)
                if (detailData.formBody.step20Comment !== undefined)
                    setSamComment(detailData.formBody.step10Comment)

                if (detailData.formHeader.stepId > 20)
                    setSamComment(detailData.formBody.step20Comment)
                if (detailData?.formHeader?.stepId === 5)
                    setDecision("")
                if (detailData.disposalEquipmentDTOs?.length > 0) {
                    let disposalData = [...detailData.disposalEquipmentDTOs]

                    disposalData.map((drItem) => {
                        drItem.company = drItem.longCompanyName
                        drItem.functionVal = drItem.functionName
                        drItem.oemName = drItem.oemName
                        drItem.model = drItem.modelName
                        drItem.modelName = drItem.modelName
                        drItem.serialNumber = drItem.serialNo
                        drItem.p1idNumber = drItem.sapP1Ref
                        drItem.mrfNumber = drItem.mrfNumber
                        drItem.decesionLevel = ''

                        if (detailData.formHeader.stepId === 20 && drItem.drRule === 'Scrap Immediately')
                            drItem.finalLevel = 'Scrapped'
                        else
                            drItem.finalLevel = ''
                        if (detailData.formHeader.stepId === 20 && drItem.drRule === '3 Months Publishing' && (drItem.drDecision === '' || drItem.drDecision === null))
                            drItem.decesionLevel = 'Scrap'
                        else
                            drItem.decesionLevel = ''


                        drItem.condition = {
                            id: drItem.fkCondition,
                            label: drItem.conditionName
                        }
                        let tmpCurrency = {}
                        tmpCurrency = currencies.find((currItem) => currItem.pkCurrency === drItem.fkCurrency)
                        drItem.fkNetBookValueCurrency = {
                            id: drItem.fkCurrency,
                            label: tmpCurrency?.currencyName !== undefined ? tmpCurrency.currencyName : ''
                        }
                        if (drItem.netBookValueValidity !== undefined && drItem.netBookValueValidity !== null)
                            drItem.netBookValueValidity = drItem.netBookValueValidity.indexOf('Z') === -1 ? drItem.netBookValueValidity + 'Z' : drItem.netBookValueValidity
                        if (drItem.availability !== undefined && drItem.availability !== null)
                            drItem.availability = drItem.availability.indexOf('Z') === -1 ? drItem.availability + 'Z' : drItem.availability
                        if (drItem.drDecisionDate !== undefined && drItem.drDecisionDate !== null)
                            drItem.drDecisionDate = drItem.drDecisionDate.indexOf('Z') === -1 ? drItem.drDecisionDate + 'Z' : drItem.drDecisionDate
                        if (drItem.inSilentStoreSince !== undefined && drItem.inSilentStoreSince !== null)
                            drItem.inSilentStoreSince = drItem.inSilentStoreSince.indexOf('Z') === -1 ? drItem.inSilentStoreSince + 'Z' : drItem.inSilentStoreSince

                        return drItem
                    })
                    setRowsData([...disposalData])
                    let fromDRPrintList=[];

                    if (disposalData !== undefined && disposalData.length>0) {
                        //Changes to be done
                        let iCounter=0;
                        disposalData.forEach((drItem,index) => {
                            let tmpObj = {}
                            tmpObj = {
                                id:index+1,
                                company : drItem.companyName,
                                pkEquipment:drItem.pkEquipment,
                                location : drItem.functionName,
                                status:drItem.status,
                                serialNo:drItem.serialNo,
                                sapP1Ref:drItem.sapP1Ref,
                                condition:drItem.condition.label,
                                availability:drItem.availability,
                                netBookValue:drItem.netBookValue,
                                fkNetBookValueCurrency:drItem.fkNetBookValueCurrency.label,
                                netBookValueValidity:drItem.netBookValueValidity,
                                availability:drItem.availability,
                                equipmentComment:drItem.equipmentComment,
                                drRule:drItem.drRule,
                                drDecision:drItem.drDecision,
                                drDecisionBy:drItem.drDecisionBy,
                                drDecisionDate:drItem.drDecisionDate,
                                drFinalStatus:drItem.drFinalStatus,
                                recoverPart:drItem.recoverPart,
                                retrievePart:drItem.retrievePart,
                                oemName : drItem.oemName,
                                model : drItem.modelName,
                                modelName : drItem.modelName,
                                area:drItem.area,
                                functionVal:drItem.functionVal
                            }
                            fromDRPrintList.push(tmpObj)
                        })
                        if(fromDRPrintList.length>0)
                        {
                            setDRFormPrintList(fromDRPrintList);
                            if(fromDRPrintList.length>0)
                            {
                               setDRFormPrintCount5(true)
                            }
                            if(fromDRPrintList.length>5)
                            {
                                setDRFormPrintCount5(false)
                               setDRFormPrintCount10(true)
                            }
                            if(fromDRPrintList.length>10)
                            {
                                setDRFormPrintCount5(false)
                                setDRFormPrintCount10(false);
                                setDRFormPrintCount20(true);
                            }
                            if(fromDRPrintList.length>20)
                            {
                                setDRFormPrintCount5(false)
                                setDRFormPrintCount10(false);
                                setDRFormPrintCount20(false);
                                setDRFormPrintCount40(true);
                            }
                            if(fromDRPrintList.length>40)
                            {
                                setDRFormPrintCount5(false)
                                setDRFormPrintCount10(false);
                                setDRFormPrintCount20(false);
                                setDRFormPrintCount40(false);
                                setDRFormPrintCount60(true);
                            }
                            if(fromDRPrintList.length>60)
                            {
                                setDRFormPrintCount5(false)
                                setDRFormPrintCount10(false);
                                setDRFormPrintCount20(false);
                                setDRFormPrintCount40(false);
                                setDRFormPrintCount60(false);
                                setDRFormPrintCount80(true);
                            }
                        }
            
                        
                    }

                }

                let fromHeaderList=[];
                let fromHdrList=[];
                if (detailData.formRequester!==undefined) 
                {
                    fromHeaderList=detailData.formRequester
                    // fromHeaderList.forEach((item,index) => {
                        let tmpObj1 = {}
                        tmpObj1 = {
                            id: 1,
                            name: fromHeaderList.displayName,
                            email:fromHeaderList.email,
                            alias: fromHeaderList.alias,
                            phonenumber: fromHeaderList.phone,
                            title:fromHeaderList.title,
                            department:fromHeaderList.department,
                            country:fromHeaderList.country,
                            company:fromHeaderList.company,
                            formtype:"",
                            lastupdatedate:"",
                            pkform:"",
                            requestdate:"",
                            statename:"",
                            stepname:""
                        }                        
                         fromHdrList.push(tmpObj1)
                    // })
                    
                    //setFromHeaderList(fromHdrList);
                }
                if (detailData.formHeader!==undefined) 
                {
                    fromHeaderList=detailData.formHeader
                    // fromHeaderList.forEach((item,index) => {
                     let tmpOdjUpd=fromHdrList;
                     if(tmpOdjUpd!==undefined)   
                     {
                        tmpOdjUpd[0].formtype=fromHeaderList.formType;
                        tmpOdjUpd[0].statename=fromHeaderList.stateName;
                        tmpOdjUpd[0].stepname=fromHeaderList.stepName;
                        tmpOdjUpd[0].requestdate=fromHeaderList.requestDate;    
                        tmpOdjUpd[0].lastupdatedate=fromHeaderList.lastUpdateDate;    
                        tmpOdjUpd[0].pkform=fromHeaderList.pkForm;
                     }

                    //  if(tmpOdjUpd!==undefined)
                    //  {
                    //     tmpOdjUpd.formtype=fromHeaderList.formtype
                    //  }
                    
                    //     fromHdrList.push(tmpOdjUpd)
                    // })
                    
                    setFromDRHeaderList(tmpOdjUpd);
                }

            }
        }
        else
            setDrData({})

    }, [detailData, isDetailLoading]);

    const handleBackClick = () => {
        setView();
        setDrData({});
        setRowsData({
            pkEquipment: '',
            location: '',
            salary: '',
            netBookValue: ''
        });
    }

    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(actions.openDrawer({ drawerOpen: !open }));
    };

    const handleUpdate = () => {
        let message = validateSubmit('update')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                let tmpCurrency = ''
                if (asd.fkNetBookValueCurrency === '')
                    tmpCurrency = 'USD'
                else if (asd.fkNetBookValueCurrency?.id === null)
                    tmpCurrency = 'USD'
                else
                    tmpCurrency = asd.fkNetBookValueCurrency?.id
                return {
                    pkEquipment: asd.pkEquipment,
                    sapP1Ref: asd.p1idNumber,
                    fkCondition: asd.condition?.id,
                    inSilentStoreSince: asd.inSilentStoreSince === null ? null : asd.inSilentStoreSince,
                    netBookValue: asd.netBookValue,
                    fkCurrency: tmpCurrency,
                    netBookValueValidity: asd.netBookValueValidity,
                    availability: asd.availability,
                    scrapImmediately: asd.scrapImmediately || false,
                    drRule: asd.drRule,
                    drDecision: asd.drDecision,
                    drDecisionDate: asd.drDecisionDate,
                    recoverParts: asd.recoverParts,
                    retrievePart: asd.retrievePart,
                    RecoverPart: asd.recoverParts,
                    drFinalStatus: asd.drFinalStatus,
                    equipmentComment: asd.equipmentComment
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "disposalEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": sidList.length > 0 ? [...sidList] : [],
            }

            dispatch(updateAction(dd))
            setNotificationAction('update')
        }
    }

    const handleCancel = () => {
        let message = validateSubmit('cancel')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                let tmpCurrency = ''
                if (asd.fkNetBookValueCurrency === '')
                    tmpCurrency = 'USD'
                else if (asd.fkNetBookValueCurrency?.id === null)
                    tmpCurrency = 'USD'
                else
                    tmpCurrency = asd.fkNetBookValueCurrency?.id
                return {
                    pkEquipment: asd.pkEquipment,
                    sapP1Ref: asd.p1idNumber,
                    fkCondition: asd.condition?.id,
                    inSilentStoreSince: asd.inSilentStoreSince === null ? null : asd.inSilentStoreSince,
                    netBookValue: asd.netBookValue,
                    fkCurrency: tmpCurrency,
                    netBookValueValidity: asd.netBookValueValidity,
                    availability: asd.availability,
                    scrapImmediately: asd.scrapImmediately || false,
                    drRule: asd.drRule,
                    drDecision: asd.drDecision,
                    drDecisionDate: asd.drDecisionDate,
                    recoverParts: asd.recoverParts,
                    retrievePart:asd.retrievePart,
                    RecoverPart: asd.RecoverPart,
                    drFinalStatus: asd.drFinalStatus,
                    equipmentComment: asd.equipmentComment
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "disposalEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": sidList.length > 0 ? [...sidList] : [],
            }
            console.log('dispathObj', dd)
            dispatch(cancelAction(dd))
            setNotificationAction('cancel')
        }
    }

    const handleDecision = () => {
        let message = validateSubmit('decision')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                let tmpCurrency = ''
                if (asd.fkNetBookValueCurrency === '')
                    tmpCurrency = 'USD'
                else if (asd.fkNetBookValueCurrency?.id === null)
                    tmpCurrency = 'USD'
                else
                    tmpCurrency = asd.fkNetBookValueCurrency?.id
                return {
                    pkEquipment: asd.pkEquipment,
                    sapP1Ref: asd.p1idNumber,
                    fkCondition: asd.condition?.id,
                    inSilentStoreSince: asd.inSilentStoreSince === null ? null : asd.inSilentStoreSince,
                    netBookValue: asd.netBookValue,
                    fkCurrency: tmpCurrency,
                    netBookValueValidity: asd.netBookValueValidity,
                    availability: asd.availability,
                    scrapImmediately: asd.scrapImmediately || false,
                    drRule: asd.drRule,
                    drDecision: asd.drDecision,
                    drDecisionDate: asd.drDecisionDate,
                    recoverParts: asd.recoverParts,
                    drFinalStatus: "",
                    equipmentComment: asd.equipmentComment
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "disposalEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": sidList.length > 0 ? [...sidList] : [],
            }
            dispatch(decisionAction(dd))
            setNotificationAction('update')
        }
    }
    const handleComplete = () => {
        let message = validateSubmit('complete')
        if (message !== '')
            toggleAlertModal({ open: true, message: message });
        else {
            setIsError(false)
            const eqDTO = rowsData.filter(({ pkEquipment }) => pkEquipment).map((asd) => {
                let tmpCurrency = ''
                if (asd.fkNetBookValueCurrency === '')
                    tmpCurrency = 'USD'
                else if (asd.fkNetBookValueCurrency?.id === null)
                    tmpCurrency = 'USD'
                else
                    tmpCurrency = asd.fkNetBookValueCurrency?.id
                return {
                    pkEquipment: asd.pkEquipment,
                    sapP1Ref: asd.p1idNumber,
                    fkCondition: asd.condition?.id,
                    inSilentStoreSince: asd.inSilentStoreSince === null ? null : asd.inSilentStoreSince,
                    netBookValue: asd.netBookValue,
                    fkCurrency: tmpCurrency,
                    netBookValueValidity: asd.netBookValueValidity,
                    availability: asd.availability,
                    scrapImmediately: asd.scrapImmediately || false,
                    drRule: asd.drRule,
                    drDecision: asd.drDecision,
                    drDecisionDate: asd.drDecisionDate,
                    recoverParts: asd.recoverParts,
                    retrievePart:asd.retrievePart,
                    RecoverPart: asd.RecoverPart,
                    drFinalStatus: asd.drFinalStatus,
                    equipmentComment: asd.equipmentComment
                }
            })
            const sidList = []
            finalNameList.forEach((item) => sidList.push(item.emailAddress))
            const docList = []
            documentList.forEach((item) => {
                let tmpObj = {}
                tmpObj = {
                    pkFormDocument: item.pkFormDocument,
                    fkForm: item.fkForm,
                    documentName: item.documentName,
                    documentMimeType: item.documentMimeType,
                    uploadedByDisplayName: item.uploadedByDisplayName,
                    insertDate: item.insertDate
                }
                docList.push(tmpObj)
            })
            const dd = {
                "pkForm": FormId,
                "fkCompany": company.id,
                "requesterCompanyIsTPM": isTpm ? 1 : 0,
                "comment": comment,
                "step10Decision": decision,
                "step10DecisionComment": samComment,
                "disposalEquipmentDTOs": eqDTO,
                "formDocuments": [...docList],
                "contactSid": sidList.length > 0 ? [...sidList] : [],
            }
            dispatch(completeAction(dd))
            setNotificationAction('complete')
        }
    }
    const columns = [
        {
            field: 'command',
            headerName: 'Command',
            flex: 1,
            disableReorder: true,
            hideSortIcons: true,
            sortable: false,
            filterable: false,
            headerClassName: "dgHeader",
            renderCell: ({ row }) => {
                let returnEll = ''
                if (!formDisabled) {
                    returnEll = <Stack direction="row" spacing={1}>
                        <IconButton color="error" aria-label="Edit">
                            <ClearOutlinedIcon onClick={(e) => onDeleteClick(e, row)} />
                        </IconButton>
                    </Stack>
                }
                return returnEll
            }
        },
        { field: 'pkFormDocument', headerName: `document Id`, flex: 1, headerClassName: "dgHeader", },
        {
            field: 'documentName', headerName: `Document name`, flex: 2, headerClassName: "dgHeader", renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => downloadDocClick(row)}
                    >
                        {(row.fkForm !== null) ?
                            <Typography sx={{ fontWeight: '600' }}>
                                {row.documentName}
                            </Typography>
                            :
                            <span>{row.documentName}</span>
                        }
                    </Link>
                </Stack>
            )
        },
        {
            field: 'insertDate', headerName: `Uploaded date`, flex: 1, headerClassName: "dgHeader", renderCell: ({ row }) => (
                <Stack direction="row" spacing={1}>
                    {moment(row.insertDate).format('MM/DD/YYYY')}
                </Stack>
            )
        },
        { field: 'uploadedByDisplayName', headerName: `Uploaded by`, flex: 2, headerClassName: "dgHeader", },
    ];
    const tooltipMessage = <span><u>Info:</u><br />Max file size (Kb) :4096
        <br />Only file of type: MS Word(.doc / .docx); MS Excel(.xlsx);
        Adobe PDF(.pdf); MS Outlook Message(.msg); text file(.txt) and Picture(.jpg /.jpeg) are authorized</span>
    return <>
        {isPageLoading ?
            <Box><LinearProgress /></Box>
            :
            <MainCard boxShadow={pageView === 'confirmation' ? false : true} border={false}>
                <DeleteModal modalData={openDeleteModal} deleteConfirm={deleteConfirm} toggleModal={() => toggleDeleteModal({})} />
                {pageView === 'newDisposal' || pageView === 'search' ?
                    <Box sx={{ p: 1, display: pageView === 'search' ? 'none' : 'block' }}>
                        {needBack ? <Grid item sx={{ p: 2 }} xs={12} sm={1}>
                        <Box display="flex" justifyContent="flex-end">
                                <Button onClick={() => { handleDrawerToggle();}} variant="text">Collapse Menu</Button>
                                <Button onClick={() => { handlePrintClick();}} variant="text">Print</Button>
                            </Box>
                        </Grid> : ""}
                        <AlertModal modalData={openAlertModal} toggleModal={() => toggleAlertModal({})} />
                        <>
                            <Grid container spacing={1} display="block">
                                {/* <FormIntial formType="DR" initialInfo={initialInfo} /> */}
                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '300px',  width:`1500px`}
                                                    }>
                                                           <StyledDataGrid 
                                                            columns={columnc}
                                                            rows={DRHeaderList}
                                                            hideCSV={true}
                                                            showQuickFilter={false}
                                                            hideFooterPagination
                                                            getRowHeight={() => 'auto'} 
                                                        />
                                                    </div>
                                                </Grid>

                            </Grid>
                            <Grid container spacing={1} sx={{ pl: 2, pr: 2 }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                        <Box>
                                            <Badge badgeContent={1} color="primary" size='large'></Badge>
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600', display: 'inline', ml: 2 }}>
                                                REQUEST DETAILS
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} display='flex'>
                                        <Grid item xs={12} sm={3}>
                                            <Box>
                                                <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Requestor company:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={9} display="flex">
                                            <Grid item xs={12} sm={6} >
                                                <Box >
                                                    <Autocomplete
                                                        disablePortal
                                                        disableClearable
                                                        size="small"
                                                        id="company"
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        disabled={formDisabled}
                                                        name="company"
                                                        value={company ? { id: company.id, label: company.label } : null}
                                                        options={companyLists.map(({ pkCompany, longCompanyName }) => ({ id: pkCompany, label: longCompanyName }))}
                                                        onChange={onCompanyChange}
                                                        renderInput={(params) => <TextField
                                                            fullWidth
                                                            maxwidth="20px"
                                                            {...params}
                                                            label={"Please select company"}
                                                        />
                                                        }
                                                    />

                                                </Box>
                                            </Grid>
                                            {isError && company === '' ? <span style={{ color: 'red' }}>*</span> : ''}
                                            <Grid item xs={12} sm={3}>
                                                <Box sx={{ display: 'inline', ml: 3 }}>Requester Region: {regionName !== undefined ? regionName : ''}</Box>
                                            </Grid>
                                            <Grid item xs={12} sm={3} sx={{ ml: 3 }}>
                                                <FormControlLabel labelPlacement="start"
                                                    control={
                                                        <Checkbox
                                                            checked={isTpm}
                                                            name='isTpm'
                                                            id='isTpm'
                                                            onChange={(event) => toggleTPM(event)}
                                                            size="small"
                                                        />
                                                    }
                                                    disabled={formDisabled}
                                                    label={'Is TPM:'} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container >
                                    <Grid item xs={12} sm={12} style={{ display: 'flex' }} sx={{ mt: 2 }}>
                                        <Grid item xs={12} sm={3}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>SAM DR Ref:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <Box>{drData?.formBody?.samDrRef !== undefined ? drData.formBody.samDrRef : 'N/A'}</Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} display="flex">
                                        <Grid item xs={12} sm={3}>
                                            <Box display="flex">
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Contact(s):</Typography>
                                                {finalNameList.length === 0 && isError ? <span style={{ color: 'red' }}>*</span> : ''}
                                            </Box>
                                        </Grid>
                                        {isContactShow ?
                                            <Grid item xs={12} sm={9} display="flex">
                                                <Grid xs={12} sm={6}>
                                                    <div style={{ outline: '1px solid grey', height: "200px", maxHeight: '200px', overflowY: 'auto' }} >
                                                        <List>
                                                            {finalNameList.map((item) => {
                                                                let selectedFieldName = `isSelected${item.value}`
                                                                return <ListItem>
                                                                    <ListItemButton selected={item[selectedFieldName]} onClick={() => handleListClick(item)}>{item.label}</ListItemButton>
                                                                </ListItem>
                                                            }
                                                            )}
                                                        </List>
                                                    </div>
                                                </Grid>
                                                {!formDisabled ?
                                                    <Grid>
                                                        <IconButton color="primary" aria-label="User" onClick={openSearch}>
                                                            <PersonOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    : ''
                                                }
                                                {!formDisabled ?
                                                    <Grid >
                                                        <IconButton color="error" aria-label="Delete" onClick={handleDelete}>
                                                            <ClearOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    : ''
                                                }
                                            </Grid>
                                            :
                                            <Grid item xs={12} sm={9}>
                                                <Grid item xs={12} sm={12} display="flex">
                                                    <Grid item xs={12} sm={2}>
                                                        <Box>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Step 1: Search:</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid sx={{pr: 2}} item xs={12} sm={6}>
                                                        <TextField
                                                            label="For best results use Last Name"
                                                            id="contact"
                                                            fullWidth
                                                            size="small"                                                            
                                                            name="contact"
                                                            onChange={onContactChange}
                                                        />
                                                    </Grid>
                                                    <Grid>
                                                        <IconButton color="primary" aria-label="User" onClick={handleSearch}>
                                                            <SearchOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid>
                                                        <IconButton color="error" aria-label="Cancel" onClick={() => toggleSearchModal(false)}>
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} display="flex">
                                                    <Grid item xs={12} sm={2}>
                                                        <Box>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Step 2: Select:</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12} sm={6}>
                                                        {isContactLoading ? <CircularProgress size={30} /> :
                                                            <MultiSelect
                                                                error=""
                                                                helperText=""
                                                                onChange={onNameChange}
                                                                name='name'
                                                                data={{ label: "Contacts", options: selectedNameList }}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid>
                                                        <Tooltip title="Please select searched contact(s) and click on add icon">
                                                        <IconButton color="primary" aria-label="User" onClick={handleAdd}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Requested Equipment(s):</Typography>
                                        </Box>
                                        <Box>
                                            Use the <Link onClick={() => { setPageView('search') }} component="button" variant="body2" style={{ fontSize: '16px' }}> Search Eqipment</Link> page from the SAM database to find the SAM ID of the equipment
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }}>
                                {DRFormPrint5?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '1000px',  width:`1400px`}
                                                    }>
                                                           <StyledDataGrid 
                                                            columns={column}
                                                            rows={DRFormPrintList}
                                                            hideCSV={true}
                                                            getRowHeight={() => 'auto'} 
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}                                    
                                {DRFormPrint10?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '1800px',  width:`1500px`}
                                                    }>
                                                           <StyledDataGrid 
                                                            columns={column}
                                                            rows={DRFormPrintList}
                                                            hideCSV={true}
                                                            getRowHeight={() => 'auto'} 
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                                {DRFormPrint20?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '3200px',  width:`1500px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={DRFormPrintList}
                                                            hideCSV={true}
                                                            getRowHeight={() => 'auto'} 
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                                {DRFormPrint40?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '6000px',  width:`1500px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={DRFormPrintList}
                                                            hideCSV={true}
                                                            getRowHeight={() => 'auto'} 
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                               {DRFormPrint60?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '10000px',  width:`1500px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={DRFormPrintList}
                                                            hideCSV={true}
                                                            getRowHeight={() => 'auto'} 
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}
                                               {DRFormPrint80?
                                                <Grid container sx={{ mt: 0 }}>
                                                    <div style={
                                                            { height: '14000px',  width:`1500px`}
                                                    }>
                                                           <StyledDataGrid
                                                            columns={column}
                                                            rows={DRFormPrintList}
                                                            hideCSV={true}
                                                            getRowHeight={() => 'auto'} 
                                                        />
                                                    </div>
                                                </Grid>
                                                :''}                                                

                                </Grid>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid item xs={12} sm={12} display="flex">
                                        <TextField
                                            id="justification-information-message"
                                            label="Justification/Information/Message"
                                            name="comment"
                                            multiline
                                            value={comment}
                                            onChange={(e) => { setComment(e.target.value) }}
                                            rows={4}
                                            sx={{ width: '100%' }}
                                            variant="outlined"
                                            disabled={(initialInfo?.formInfo?.stepId === 0 || initialInfo?.formInfo?.stepId === 5) ? false : true}
                                        />
                                        {isError && comment === '' ? <span style={{ color: 'red' }}>*</span> : ''}
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 3 }}>
                                    <Grid item xs={12} sm={3}>
                                        <Box>
                                            <Typography>File attachment(s):</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        {!formDisabled ?
                                            <>
                                                <Box sx={{ mt: 1 }}>
                                                    <Tooltip title={tooltipMessage}
                                                        componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    backgroundColor: '#ced4da',
                                                                    color: 'black',
                                                                    fontSize: '12px'
                                                                }
                                                            }
                                                        }}><InfoIcon color='primary' /></Tooltip><input multiple type="file" onChange={handleFileUpload} />
                                                </Box>
                                                <Box sx={{ mt: 1 }}>
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        onClick={saveFiles}>
                                                        + Add File{ }
                                                    </Button>
                                                </Box>
                                            </>
                                            : ''
                                        }
                                        {documentList.length === 0 ?
                                            <Box sx={{ mt: 1 }}>
                                                <Typography>Current uploaded files: None</Typography>
                                            </Box>
                                            : ''}
                                    </Grid>
                                </Grid>
                                {documentList.length === 0 ? '' :
                                    <Grid container display="flex" justifyContent="center">
                                        <Grid item xs={12} sm={8}>
                                            <Box sx={{ mt: 1 }}>
                                                <Grid container sx={{ mt: 3 }}>
                                                    <div style={{
                                                        height: '300px',
                                                        width: '100%'
                                                    }}>
                                                        <StyledDataGrid
                                                            columns={columns}
                                                            rows={documentList}
                                                            loading={isDocLoading}
                                                            csvOptions={{
                                                                fileName: 'Documents'
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                {initialInfo?.formInfo?.stepId === 0 ?
                                    <Grid container sx={{ mt: 2 }}>
                                        <Grid item pt={3} sm={12}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    sx={{ "marginRight": "1rem" }}
                                                    size="small"
                                                    onClick={handleSubmit}
                                                >Submit</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    : ''
                                }
                                {(initialInfo?.formInfo?.stepId === 5 && !formDisabled) ?
                                    <Grid container sx={{ mt: 2 }}>
                                        <Grid item pt={3} sm={12} >
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    startIcon={<CheckIcon />}
                                                    sx={{ "marginRight": "1rem" }}
                                                    onClick={handleReSubmit}
                                                >
                                                    Re-Submit
                                                </Button>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    startIcon={<CancelIcon />}
                                                    sx={{ "marginRight": "1rem" }}
                                                    onClick={handleCancel}
                                                >
                                                    Cancel the Request
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    : ''
                                }
                            </Grid>
                            {(initialInfo?.formInfo?.stepId !== 0) ?
                                <Grid container >
                                    <Grid container >
                                        <Grid item xs={12} sx={{ mt: 3 }}>
                                            <Box>
                                                <Badge badgeContent={2} color="primary" size='large'></Badge>
                                                <Typography sx={{ fontSize: '16px', fontWeight: '600', display: 'inline', ml: 2 }}>
                                                    SAM Team Decision
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mt: 2 }}>
                                        <Grid item xs={12} sm={12} display='flex'>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Decision:{isError && decision === '' ? <span style={{ color: 'red' }}>*</span> : ''}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={9} display="flex">
                                                <Grid item xs={12} sm={3}>
                                                    <Box >
                                                        <FormControl fullWidth>
                                                            <InputLabel id="decision-label">{decisionList.label}</InputLabel>
                                                            <Select
                                                                labelId="decision-label"
                                                                id="decision"
                                                                name="decision"
                                                                value={decision}
                                                                label={decisionList.label}
                                                                onChange={onDecisionChange}
                                                                disabled={formDisabled || (initialInfo?.formInfo?.stepId === 20)}
                                                            >
                                                                {decisionList.options.map((a, index) => <MenuItem key={index} value={a.value}>{a.label}</MenuItem>)}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mt: 3 }}>
                                        <Grid item xs={12} sm={5} display="flex">
                                            <TextField
                                                id="sam-comment"
                                                label="Sam Comment"
                                                name="samComment"
                                                multiline
                                                value={samComment}
                                                onChange={(e) => { setSamComment(e.target.value) }}
                                                rows={4}
                                                sx={{ width: '100%' }}
                                                variant="outlined"
                                                disabled={formDisabled}
                                            />
                                            {(isError && samComment === '' && decision === 'Reject') ? <span style={{ color: 'red' }}>*</span> : ''}
                                        </Grid>
                                    </Grid>
                                    {(initialInfo?.formInfo?.stepId === 10 && !formDisabled) ?
                                        <Grid container >
                                            <Grid item pt={3} sm={12}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        sx={{ "marginRight": "1rem" }}
                                                        size="small"
                                                        onClick={handleDecision}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        : ''
                                    }
                                </Grid>
                                : ''
                            }
                            {(initialInfo?.formInfo?.stepId === 20) ?
                                <Grid container sx={{ mt: 3 }}>
                                    <Grid container >
                                        <Grid item xs={12} sx={{ mt: 3 }}>
                                            <Box>
                                                <Badge badgeContent={3} color="primary" size='large'></Badge>
                                                <Typography sx={{ fontSize: '16px', fontWeight: '600', display: 'inline', ml: 2 }}>
                                                    SAM Team Execution
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {!formDisabled ?
                                        <Grid container >
                                            <Grid item pt={3} sm={12}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        startIcon={<CancelIcon />}
                                                        sx={{ "marginRight": "1rem" }}
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel the Request
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        startIcon={<CheckIcon />}
                                                        sx={{ "marginRight": "1rem" }}
                                                        size="small"
                                                        onClick={handleUpdate}
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        startIcon={<CheckIcon />}
                                                        sx={{ "marginRight": "1rem" }}
                                                        onClick={handleComplete}
                                                    >
                                                        Complete
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        : ''}
                                </Grid>
                                : ''
                            }
                            <Accordion expanded={historyExpanded} onChange={() => setHistoryExpanded(!historyExpanded)} sx={{ boxShadow: 'none' }}>
                                <AccordionSummaryRev
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Box sx={{ fontWeight: 600, display: 'block' }}>Form History Log</Box>
                                        </Grid>
                                    </Grid>
                                </AccordionSummaryRev>
                                <AccordionDetails>
                                    <Grid container >
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Date:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>By:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Action:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Step:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Comment:</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {drData?.formLog?.length > 0 ?
                                        drData.formLog.map((item) =>
                                            <Grid container>
                                                <Divider sx={{ my: 2, borderBottomWidth: 2, width: '100%' }} />
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{moment(item.actionDate).format('MM/DD/YYYY')}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.actionBy}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.action}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.step}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px' }}>{item.comment}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        <Grid item xs={12} sm={12}>
                                            <Divider sx={{ my: 2, borderBottomWidth: 2, width: '100%' }} />
                                            <Box>No history</Box>
                                        </Grid>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </>
                    </Box>
                    : ''}
                {pageView === 'search' ? <SearchEquipment setPageView={() => { setPageView('newDisposal') }} needBack={true} /> : ''}
                {pageView === 'confirmation' ? <ConfirmationPage formType="DR" notificationAction={notificationAction} formId={FormId} setPageView={setPageView} /> : ''}
            </MainCard>
        }
    </>
}