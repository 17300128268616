import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';

const menuItems = [
    {
        id: "root",
        title: "",
        type: "group",
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "item",
                url: "/dashboard",
                icon: DashboardCustomizeOutlinedIcon,
                breadcrumbs: false,
                role: ['admin', 'coordinator', 'rr', 'developer', 'reader'],
            },
            {
                id: 'search-or-views',
                title: 'Search / Views',
                type: 'collapse',
                breadcrumbs: false,
                icon: SearchOutlinedIcon,
                role: ['admin', 'coordinator', 'rr', 'developer', 'reader'],
                scope: ['search_equipment', 'equipment_by_company', 'company-list', 'coordinators-list', 'equipment-actions'],
                children: [
                    {
                        id: 'equipment-lists',
                        title: 'Equipment Lists',
                        type: 'collapse',
                        role: ['admin', 'coordinator', 'rr', 'developer', 'reader'],
                        children: [
                            {
                                id: 'search-equipment',
                                title: 'Search Equipment',
                                type: 'item',
                                url: '/search-views/search-equipment',
                                breadcrumbs: true,
                                scope: 'search_equipment'
                            },
                            {
                                id: 'equipment-by-company',
                                title: 'Equipment by Company',
                                type: 'item',
                                url: '/search-views/equipment-company',
                                breadcrumbs: true,
                                scope: 'search_equipment'
                            },
                            {
                                id: 'equipment-for-disposal',
                                title: 'Search Disposal Request',
                                type: 'item',
                                url: '/search-views/disposal-search',
                                breadcrumbs: true,
                                scope: 'search_equipment'
                            },
                            {
                                id: 'equipment-for-reservation',
                                title: 'Search Equipment Reservation',
                                type: 'item',
                                url: '/search-views/reservation-search',
                                breadcrumbs: true,
                                scope: 'search_equipment'
                            }
                        ]
                    },
                    {
                        id: 'companiesLists',
                        title: 'Companies Lists',
                        type: 'item',
                        breadcrumbs: true,
                        url: '/search-views/companies-lists',
                        scope: 'companies-lists',
                        role: ['admin', 'coordinator', 'rr', 'developer', 'reader'],
                    },
                    {
                        id: 'coordinatorsList',
                        title: 'Coordinators List',
                        type: 'item',
                        url: '/search-views/coordinators-list',
                        breadcrumbs: true,
                        scope: 'coordinators-list',
                        role: ['admin', 'coordinator', 'rr', 'developer', 'reader'],
                    },
                    {
                        id: 'functional-structure-ref',
                        title: 'Functional structure',
                        type: 'item',
                        url: '/search-views/functional-structure-ref',
                        breadcrumbs: true,
                        scope: 'search_equipment',
                        role: ['admin', 'coordinator', 'rr', 'developer'],
                    }
                ]
            },
            {
                id: 'management',
                title: 'Management',
                type: 'collapse',
                breadcrumbs: true,
                icon: ManageAccountsOutlinedIcon,
                scope: ['management'],
                role: ['admin', 'coordinator', 'rr', 'developer'],
                children: [
                    {
                        id: 'coordinatorHomepage',
                        title: 'Coordinator Homepage',
                        type: 'collapse',
                        breadcrumbs: true,
                        scope: ['admin'],
                        role: ['coordinator', 'admin'],
                        children: [
                            {
                                id: 'company',
                                title: 'Edit Functional Locations',
                                type: 'item',
                                url: '/management/functional-Locations',
                                breadcrumbs: true,
                                scope: ['company'],
                                role: ['coordinator']
                            },
                            {
                                id: 'Equipment',
                                title: 'Create new Equipment',
                                type: 'item',
                                breadcrumbs: true,
                                url: '/management/create-new-eq',
                                scope: ['Equipment'],
                                role: ['coordinator']
                            },
                            {
                                id: 'lists',
                                title: 'Lists',
                                type: 'collapse',
                                children: [
                                    {
                                        id: 'models',
                                        title: 'Models',
                                        type: 'item',
                                        url: '/management/models',
                                        breadcrumbs: true,
                                        scope: 'models'
                                    }]
			                }
                    ]
                }
                   ,
                    {
                        id: 'admin',
                        title: 'Admin Homepage',
                        type: 'collapse',
                        breadcrumbs: true,
                        scope: ['admin'],
                        role: ['admin'],
                        children: [
                            {
                                id: 'security',
                                title: 'Security',
                                type: 'collapse',
                                breadcrumbs: true,
                                scope: ['security'],
                                children: [
                                    {
                                        id: 'administratorRole',
                                        title: 'SAM DB Administrators role',
                                        type: 'item',
                                        url: '/management/administratorRole',
                                        breadcrumbs: true,
                                        scope: 'administratorRole'
                                    },
                                    {
                                        id: 'administratorCoordinators',
                                        title: 'SAM DB Coordinators role',
                                        type: 'item',
                                        url: '/management/administratorCoordinators',
                                        breadcrumbs: true,
                                        scope: 'administratorCoordinators'
                                    }
                                ]
                            },
                            {
                                id: 'lists',
                                title: 'Lists',
                                type: 'collapse',
                                children: [
                                    {
                                        id: 'regions',
                                        title: 'Regions',
                                        type: 'item',
                                        url: '/management/regions',
                                        breadcrumbs: true,
                                        scope: 'regions'
                                    },
                                    {
                                        id: 'countries',
                                        title: 'Countries',
                                        type: 'item',
                                        url: '/management/countries',
                                        breadcrumbs: true,
                                        scope: 'countries',
                                    },
                                    {
                                        id: 'companies',
                                        title: 'Companies',
                                        type: 'item',
                                        url: '/management/companies',
                                        breadcrumbs: true,
                                        scope: 'companies',
                                    },
                                    {
                                        id: 'clusters',
                                        title: 'Clusters',
                                        type: 'item',
                                        url: '/management/clusters',
                                        breadcrumbs: true,
                                        scope: 'clusters'
                                    },
                                    {
                                        id: 'conditions',
                                        title: 'Conditions',
                                        type: 'item',
                                        url: '/management/conditions',
                                        breadcrumbs: true,
                                        scope: 'conditions'
                                    },
                                    {
                                        id: 'controlSystems',
                                        title: 'Control Systems',
                                        type: 'item',
                                        url: '/management/controlSystems',
                                        breadcrumbs: true,
                                        scope: 'controlSystems'
                                    },
                                    {
                                        id: 'currencies',
                                        title: 'Currencies',
                                        type: 'item',
                                        url: '/management/currencies',
                                        breadcrumbs: true,
                                        scope: 'currencies'
                                    },
                                    {
                                        id: 'speedUnits',
                                        title: 'Speed Units',
                                        type: 'item',
                                        url: '/management/speedUnits',
                                        breadcrumbs: true,
                                        scope: 'speedUnits'
                                    },
                                    {
                                        id: 'oem',
                                        title: 'OEM',
                                        type: 'item',
                                        url: '/management/oem',
                                        breadcrumbs: true,
                                        scope: 'oem'
                                    },
                                    {
                                        id: 'models',
                                        title: 'Models',
                                        type: 'item',
                                        url: '/management/models',
                                        breadcrumbs: true,
                                        scope: 'models'
                                    },
                                    {
                                        id: 'functional-structure',
                                        title: 'Functional structure',
                                        type: 'item',
                                        url: '/management/functional-structure',
                                        breadcrumbs: true,
                                        scope: 'models'
                                    },
                                    {
                                        id: 'productFormatValue',
                                        title: 'Product Format Value',
                                        type: 'item',
                                        url: '/management/productFormatValue',
                                        breadcrumbs: true,
                                        scope: 'productFormatValue'
                                    },
                                    {
                                        id: 'productFormat',
                                        title: 'Product Format',
                                        type: 'item',
                                        url: '/management/productFormat',
                                        breadcrumbs: true,
                                        scope: 'productFormat'
                                    },
                                    {
                                        id: 'functionProductFormat',
                                        title: 'Function-Product Format',
                                        type: 'item',
                                        url: '/management/functionProductFormat',
                                        breadcrumbs: true,
                                        scope: 'functionProductFormat'
                                    }
                                ]
                            },
                            {
                                id: 'reports',
                                title: 'Reports',
                                type: 'collapse',
                                scope: '[reports]',
                                children: [
                                    {
                                        id: 'equipmentActions',
                                        title: 'Equipment Actions',
                                        type: 'item',
                                        url: '/management/equipment-actions',
                                        breadcrumbs: true,
                                        scope: 'reports'
                                    },
                                    {
                                        id: 'statusofcompanyvalidation',
                                        title: 'Status of company validation',
                                        type: 'item',
                                        url: '/management/company-validation-status',
                                        breadcrumbs: true,
                                        scope: 'report'
                                    }]
                            },
                            {
                                id: 'mass-upload',
                                title: 'Mass Upload',
                                type: 'collapse',
                                children: [
                                    {
                                        id: 'download-template',
                                        title: 'Download Template',
                                        type: 'item',
                                        breadcrumbs: true,
                                        scope: 'download-template',
                                        url: '/management/downloadTemplate',
                                    },
                                    {
                                        id: 'uploadTemplate',
                                        title: 'Upload Template',
                                        type: 'item',
                                        url: '/management/uploadTemplate',
                                        breadcrumbs: true,
                                        scope: 'uploadTemplate'
                                    },
                                    {
                                        id: 'updateTemplate',
                                        title: 'Update Template',
                                        type: 'item',
                                        url: '/management/updateTemplate',
                                        breadcrumbs: true,
                                        scope: 'uploadTemplate'
                                    },
                                    {
                                        id: 'bulkUploadStatus',
                                        title: 'Bulk Upload Status',
                                        type: 'item',
                                        url: '/management/bulkUploadStatus',
                                        breadcrumbs: true,
                                        scope: 'bulkUploadStatus'
                                    },
                                ]
                            },
                            {
                                id: 'adminaction',
                                title: 'Form Initiator',
                                type: 'item',
                                url: '/management/form-initiator',
                                scope: '[action]',
                            }
                    ]
                },
                {
                     id: 'team-focus',
                     title: 'SAM Team Focus',
                     type: 'item',
                     role: ['admin'],
                     url: '/management/team-focus',
                     breadcrumbs: true,
                     scope: 'team-focus'
                    
                    }
                
            ]
        },
        {
            id: 'business-process',
            title: 'Business Process',
            type: 'collapse',
            breadcrumbs: false,
            icon: AddBusinessOutlinedIcon,
            role: ['admin','coordinator','rr','developer', 'reader'],
            scope: ['business-process'],
            url: "/business-process",
            children:
            [
                {
                    id: 'new-disposal-request',
                    title: 'New Disposal Request',
                    type: 'item',
                    url: '/business-process/new-disposal-request',
                    breadcrumbs: true,
                    scope: 'new_disposal_request',
                    role: ['admin','coordinator','rr','developer', 'reader'],
                },
                {
                    id: 'new-mr-request',
                    title: 'New Machine Reservation Request',
                    type: 'item',
                    url: '/business-process/new-mr-request',
                    breadcrumbs: true,
                    scope: 'new-mr-request',
                    role: ['admin','coordinator','rr','developer', 'reader'],
                },
            ]           
        },
        {id: 'developer-homepage',
        title: 'Developer Homepage',
        type: 'collapse',
         breadcrumbs: false,
          icon: IntegrationInstructionsOutlinedIcon,
           role: ['developer'], 
           scope: "developer-homepage", 
           url: "/developer-homePage",
           children: 
           [
                {
                   id: 'form-workflow',
                    title: 'Form WorkFlow',
                    type: 'item',
                    role: ['developer'],
                    url: '/developer-homePage/form-workflow',
                    breadcrumbs: true,
                   scope: 'form-workflow'

               },
               {
                   id: 'status',
                    title: 'Status',
                    type: 'item',
                    role: ['developer'],
                    url: '/developer-homePage/status',
                    breadcrumbs: true,
                   scope: 'status'

               },
               {
                   id: 'product-format',
                    title: 'Product Format Type',
                    type: 'item',
                    role: ['developer'],
                    url: '/developer-homePage/product-format',
                    breadcrumbs: true,
                   scope: 'product-format'

               }
           ]
               },
               {
                id: 'designSpeed',
                title: 'Design speed',
                type: 'collapse',
                breadcrumbs: true,
                icon: ManageAccountsOutlinedIcon,
                scope: ['designSpeed'],
                // role: ['admin', 'coordinator', 'rr', 'developer','dssysown','dseqown','dsuser'],
                role: ['dssysown','dseqown','dsuser','developer'],
                children: [
                    {
                        id: 'mainScreen',
                        title: 'Main Screen',
                        type: 'item',
                        breadcrumbs: true,
                        scope: ['admin'],
                        role: ['coordinator', 'admin','dssysown','dseqown','developer','dsuser'],
                        url:'/design-speed/main-screen'
                },
             {
                id: 'viewEquipment',
                title: 'View Equipment',
                type: 'item',
                breadcrumbs: true,
                url: '/design-speed/view-machine-type',
                scope: ['admin'],
                role: ['coordinator', 'admin','dssysown','dseqown','dsuser','developer'],
        },  
        {
            id: 'requestHistory',
            title: 'Request Information',
            type: 'item',
            url: '/design-speed/request-history',
            breadcrumbs: true,
            scope: ['configuration'],
            role: ['coordinator', 'admin','dssysown','dseqown','dsuser','developer'],
        },
        {
            id: 'equipmentTypeManagement',
            title: 'DesignSpeed Equipment',
            type: 'collapse',
            breadcrumbs: true,
            scope: ['configuration'],
            role: ['coordinator', 'admin','dssysown','dseqown','developer'],
            children: [
                {
                    id: 'equipmentType',
                    title: 'Equipment Type',
                    type: 'item',
                    breadcrumbs: true,
                    url:'/design-speed/equipment-type',
                    scope: ['admin'],
                    role: ['coordinator', 'admin','dseqown','dssysown','developer'],

            },
            {
                id: 'approvals',
                title: 'Approvals',
                type: 'item',
                url: '/design-speed/approvals',
                breadcrumbs: true,
                scope: ['approvals'],
                // role: ['coordinator']
            },
        
        ]
        },
        {
            id: 'configuration',
            title: 'Configuration',
            type: 'collapse',
            breadcrumbs: true,
            scope: ['admin'],
            role: ['dssysown','admin','developer'],
            children: [
                {
                    id: 'equipmentColumnMapper',
                    title: 'Equipment Column Mapper',
                    type: 'item',
                    breadcrumbs: true,
                    url:'/design-speed/equipment-column-mapper',
                    scope: ['admin'],
                    role: ['coordinator', 'admin','developer','dssysown'],
            },
            {
                id: 'ruleGroup',
                title: 'Rule Group',
                type: 'item',
                breadcrumbs: true,
                url: '/design-speed/rule-group',
                scope: ['admin', 'coordinator'],
                role: ['coordinator', 'admin','developer','dssysown'],
            },
            {
                id: 'ruleGroupConfiguration',
                title: 'Rule Group Configuration',
                type: 'item',
                breadcrumbs: true,
                url: '/design-speed/ruleGroup-configuration',
                scope: ['admin', 'coordinator'],
                role: ['coordinator', 'admin','developer','dssysown'],
            },
                {
                    id: 'security',
                    title: 'Security',
                    type: 'collapse',
                    breadcrumbs: true,
                    url: '/design-speed/security',
                    scope: ['configuration'],
                    role: ['coordinator', 'admin','developer','dssysown'],
                    children:[
                        {
                                        id: 'listOfSystemOwner',
                                        title: 'List of System Owner',
                                        type: 'item',
                                        url: '/design-speed/list-of-system-owner',
                                        breadcrumbs: true,
                                        scope: ['security'],
                                        // role: ['coordinator']
                                    },
                                    {
                                        id: 'listOfEquipmentOwner',
                                        title: 'List of Equipment Owner',
                                        type: 'item',
                                        breadcrumbs: true,
                                        url: '/design-speed/list-of-equipment-owner',
                                        scope: ['security'],
                                        // role: ['coordinator']
                                    } 
                    ]
                },
                {
                    id: 'lists',
                    title: 'Lists',
                    type: 'collapse',
                    breadcrumbs: true,
                    url: '/design-speed/lists',
                    scope: ['configurations'],
                    role: ['coordinator', 'admin','dssysown'],
                    children:[
                                    {
                                        id: 'listType',
                                        title: 'List Type',
                                        type: 'item',
                                        breadcrumbs: true,
                                        url: '/design-speed/list-type',
                                        scope: ['lists'],
                                        // role: ['coordinator']
                                    },
                                    {
                                        id: 'listTypeValue',
                                        title: 'List Type Value',
                                        type: 'item',
                                        breadcrumbs: true,
                                        url: '/design-speed/list-type-value',
                                        scope: ['lists'],
                                        // role: ['coordinator']
                                    } 
                    ],
                },
                // {
                //     id: 'machineEquipmentTypeFieldsConfiguration',
                //     title: 'Machine/Equipment Type Fields Configuration',
                //     type: 'item',
                //     url: '/management/functional-Locations',
                //     breadcrumbs: true,
                //     scope: ['configurations'],
                //     // role: ['coordinator']
                // },

        ]
    }
                
            ]
        },

        {
            id: "powerbi",
            title: "Power BI Reports",
            type: "item",
            url: "/PowerBI",
            icon: AssessmentOutlinedIcon,
            breadcrumbs: false,
            role: ['admin', 'coordinator', 'reader'],
        }
    ],
}];

export default menuItems;