import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Checkbox, FormControlLabel, TextField, Grid } from '@mui/material';
import { Autocomplete, Divider, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CircularProgress, LinearProgress } from '@mui/material/index';
import dayjs from 'dayjs';

import { createEQAction, deleteEQAction, getAllFLocationAction, getAllSpeedUnitAction, getAllStatusAction, getEqbyIdAction,checkFunctionLocationAction, updateEQAction, getParentEquipmentAction } from './EquipmentByCompany/state/actions';
import { getAreaAction } from 'containers/Management/FunctionalStructure/Area/state/actions';
import { getOemAction } from 'containers/Management/OEM/state/actions';
import { getModelAction } from 'containers/Management/Models/state/actions';
import { getFunctionAction } from 'containers/Management/FunctionalStructure/Function/state/actions';
import { getAllControlSystemAction } from 'containers/Management/ControlSystem/state/actions';
import { getAllConditionAction } from 'containers/Management/Conditions/state/actions';
import { getAllCurrencyAction } from 'containers/Management/Currencies/state/actions';
import DeleteModal from 'components/DeleteModal';
import { getAllCompanyForEquipmentAction } from './Companies/state/actions';
import FlModel from './FlModel';
import { Modal } from '@mui/material/index';
import Fade from '@mui/material/Fade';
import { getModelFunctionAction } from './FunctionalStructure/state/actions';
import { getSubPrAction } from 'containers/Management/FunctionalStructure/SubProcess/state/actions';
import { getProcessAction } from 'containers/Management/FunctionalStructure/Process/state/actions';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function GeneralEditTab({ company = {}, setView, samid, parent, setEqId, showOnlyOneSave= false }) {

    const [FlList, setflList] = useState([]);
    const [flag, setFlag] = useState(0);
    const [isSubmit, setSubmit] = useState(false); 
    const [exit, setExit] = useState(false); 
    const [speedModelOpen, setSpeedModelOpen] = useState(false);
    const [exParent, setParent] = useState(null);
    const [tempEqParent, setTempEqParent] = useState(null);
    const [pr, setPR] = useState(null);
    const [serverFl, setServerFl] = useState(null);


    const [formData, setFormData] = React.useState([]);
    const [openDeleteModal, toggleDeleteModal] = useState({});
    const [toggleFlModel, setToggleFlModel] = useState({});
    const [editPayload, setEditPayload] = useState({});
    const dispatch = useDispatch();
    const { companyforEquipmentList = [] } = useSelector((state) => state.companyforEquipmentList);
    const { areaList = [], isAreaLoading } = useSelector((state) => state.area);
    const { oemListNoD: oemList = [], isOemLoading } = useSelector((state) => state.oem);
    const { modelListNoD = [], isModelLoading } = useSelector((state) => state.model);
    const { activeFunctionList = [], isFunctionLoading } = useSelector((state) => state.function);
    const { FLocationList = [], isFLLoading } = useSelector((state) => state.FLlist);
    const { SpeedUnitList = [], isSULoading } = useSelector((state) => state.speedUnit);
    const { controlSytems = [], controlSytemsloading } = useSelector((state) => state.controlSystem);
    const { statusList = [], isStatusLoading } = useSelector((state) => state.status);
    const { conditions = [], conditionLoading } = useSelector((state) => state.conditions);
    const { addEqLoading = '', EqAdded = '', equipmentWithId = {}, getEqLoading, samIdForAddEq = '',isShowFunctionalLocationAlert } = useSelector((state) => state.equipment);
    const { currencies = [], currencyLoading } = useSelector((state) => state.currency);
    const { parentEquipmentList = [] } = useSelector((state) => state.parentEquipmentList);
    const { processListNoD : processList } = useSelector((state) => state.process);

    const { modelFunctionList = [] } = useSelector((state) => state.modelFunctiolRel);
    const { roles } = useSelector((state) => state.token);
    const [isGEAdmin, setGEIsAdmin] = useState(false);

    useEffect(() => {
        if (samid && companyforEquipmentList.length && areaList.length &&
            oemList.length && modelListNoD.length && activeFunctionList.length && SpeedUnitList.length &&
            controlSytems.length && statusList.length && conditions.length && currencies.length) {
            setTimeout(() => {
                dispatch({ type: 'GET_EQ_ID_REQUEST' })
                dispatch(getEqbyIdAction(samid));
            }, 500);
        }
    }, [samid, companyforEquipmentList.length, areaList.length,
        oemList.length, modelListNoD.length, activeFunctionList.length, SpeedUnitList.length,
        controlSytems.length, statusList.length, conditions.length, currencies.length])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
       // if (EqAdded && parent === 'EQ_CO') {
        if (EqAdded) {
            if (exit) {
            setEqId('');
            setExit(false);
            } else {
            setEqId(samIdForAddEq)
            }
            dispatch({ type: "CLEAR_EQ_ADDED" })
        }
    }, [EqAdded])

    React.useEffect(() => {
        dispatch(getAllCompanyForEquipmentAction());
        dispatch(getAreaAction());
        dispatch(getOemAction());
        dispatch(getModelAction());
        dispatch(getFunctionAction());
        dispatch(getAllSpeedUnitAction());
        dispatch(getAllControlSystemAction());
        dispatch(getAllStatusAction());
        dispatch(getSubPrAction());
        dispatch(getProcessAction());
        dispatch(getAllCurrencyAction());
        dispatch(getAllConditionAction());
        dispatch(getModelFunctionAction());
        dispatch({ type: 'CLEAR_PARENT' });
        let role = roles.includes('admin')
        if (role) {
            setGEIsAdmin(true)
        }
        
    }, [])

    // useEffect(() => {
    //     setflList(FLocationList);
    // }, [FLocationList]);

    useEffect(() => {
        if (companyforEquipmentList.length && company.id && !samid && companyforEquipmentList.find(({ pkCompany }) => pkCompany === company.id)) {
            onInputChange({ target: { value: company, name: 'fkCompany' } })
            setFormData({ ...formData, fkCompany: company })
        }
    }, [companyforEquipmentList]);

    const onInputChange = ({ target }) => {
        setSubmit(false);
        if (target.value && target.name === 'fkCompany') {
            dispatch({ type: 'CLEAR_PARENT' });
            setTimeout(() => {
                dispatch(getAllFLocationAction(target.value.id));
            }, 0);
            setFormData({ ...formData, [target.name]: target.value, fkFunctionalLocation: '', fkEquipment: '' });

        } else if (target.value && target.name === 'fkStatus') {
            setFormData({ ...formData, [target.name]: target.value, inSilentStoreSince: undefined });
        } else if (target.value && target.name === 'isTransferred') {
            setFormData({ ...formData, [target.name]: target.value, availableTransferDate: undefined, fkCondition: undefined });
        } else if (target.value && target.name === 'fkFunctionalLocation') {
            dispatch(getParentEquipmentAction({ FkArea: formData?.fkArea?.id, FkFunctionalLocation: target.value.id, equipment: samid }));
            setFormData({ ...formData, [target.name]: target.value, fkEquipment: ''});
            dispatch({ type: 'CLEAR_PARENT' });
        }
        else if (target.name === 'fkArea') {
            if (target.value && formData?.fkFunctionalLocation?.id) {
                dispatch(getParentEquipmentAction({ FkArea: target.value.id, FkFunctionalLocation: formData.fkFunctionalLocation.id, equipment: samid }));
                setFormData({ ...formData, [target.name]: target.value, equipmentOemid: '', fkEquipment: '', fkModel: '', fkFunction: '' });
            } else {
                dispatch(getParentEquipmentAction({ FkFunctionalLocation: formData.fkFunctionalLocation.id, equipment: samid }));
                setFormData({ ...formData, [target.name]: target.value, fkEquipment: '', equipmentOemid: '', fkModel: '', fkFunction: '' });
            }
        }
        else if (target.name === 'equipmentOemid') {
            if (target.value && formData?.fkFunctionalLocation?.id && formData?.fkArea?.id) {
                dispatch(getParentEquipmentAction({ equipmentOemid: target.value.id, FkFunctionalLocation: formData.fkFunctionalLocation.id, FkArea: formData.fkArea.id, equipment: samid }));
                setFormData({ ...formData, [target.name]: target.value, fkEquipment: '', fkModel: '', fkFunction: '' });
            } else {
                dispatch(getParentEquipmentAction({ FkFunctionalLocation: formData.fkFunctionalLocation.id, FkArea: formData.fkArea.id, equipment: samid }));
                setFormData({ ...formData, [target.name]: target.value, fkModel: '', fkFunction: '', fkEquipment: '' });
            }
        } 
        else {
            setFormData({ ...formData, [target.name]: target.value });
        }
    }

    const onInputModelChange = ({ target }) => {
        
        if (!formData.defaultSpeed && target.value) {
            const pew = modelListNoD.find(({pkModel})=>pkModel === target.value.id );
            const spdnm = SpeedUnitList.find(({ pkSpeedUnit }) => ( pew.fkSpeedUnit === pkSpeedUnit))
           setFormData({ ...formData,fkFunction: '', [target.name]: target.value, designSpeed : pew.designSpeed || '', fkDesignSpeedUnit: spdnm ?  { id: spdnm.pkSpeedUnit, label: spdnm.speedUnitName } : undefined});   
        }else{
            setFormData({ ...formData, [target.name]: target.value, fkFunction: '' });
        }
    }

    const onParentChange = ({ target }) => {
        setFormData({ ...formData, [target.name]: target.value });
    }

    useEffect(() => {
        if (equipmentWithId && equipmentWithId.fkCompany) {
            dispatch(getAllFLocationAction(equipmentWithId.fkCompany));
            dispatch(getParentEquipmentAction({ equipment: samid, FkArea: equipmentWithId.fkArea, FkFunctionalLocation: equipmentWithId.fkFunctionalLocation }));
        }
    }, [equipmentWithId.fkCompany])

    const getLabelId = (list, value, id, label) => {
        const abdt = list.find((a) => a[id] === value);
        return abdt ? { id: abdt[id], label: abdt[label] } : '';
    }

    useEffect(() => {
        return () => {
            dispatch({ type: "CLEAR_EQ_DATA" })
        }
    }, [])

    useEffect(() => {
        if (samid && equipmentWithId) {
            const { fkCompany, fkFunctionalLocation, fkNetBookValueCurrency,
                fkStatus, fkControlSystem, fkCondition, fkArea, fkOem,
                fkModel, fkSpeedUnit, fkFunction, fkDesignSpeedUnit,
                p1idNumber, designSpeed, availableTransferDate, inSilentStoreSince, netBookValueDate
            } = equipmentWithId;
            setFormData({
                ...equipmentWithId,
                sapP1ref: p1idNumber,
                fkCompany: fkCompany ? getLabelId(companyforEquipmentList, fkCompany, 'pkCompany', 'longCompanyName') : "",
                fkFunctionalLocation: fkFunctionalLocation ? getLabelId(FLocationList, fkFunctionalLocation, 'pkFunctionalLocation', 'functionalLocationName') : "",
                fkNetBookValueCurrency: fkNetBookValueCurrency ? getLabelId(currencies, fkNetBookValueCurrency, 'pkCurrency', 'currencyName') : "",
                fkStatus: fkStatus ? getLabelId(statusList, fkStatus, 'pkStatus', 'statusName') : "",
                fkControlSystem: fkControlSystem ? getLabelId(controlSytems, fkControlSystem, 'pkControlSystem', 'controlSystemName') : "",
                fkCondition: fkCondition ? getLabelId(conditions, fkCondition, 'pkCondition', 'conditionName') : "",
                fkArea: fkArea ? getLabelId(areaList, fkArea, 'pkArea', 'areaName') : "",
                equipmentOemid: fkOem ? getLabelId(oemList, fkOem, 'pkOem', 'oemName') : "",
                fkModel: fkModel ? getLabelId(modelListNoD, fkModel, 'pkModel', 'modelName') : "",
                fkFunction: fkFunction ? getLabelId(activeFunctionList, fkFunction, 'pkFunction', 'functionName') : "",
                fkDesignSpeedUnit: fkDesignSpeedUnit ? getLabelId(SpeedUnitList, fkDesignSpeedUnit, 'pkSpeedUnit', 'speedUnitName') : "",
                fkSpeedUnit: fkSpeedUnit ? getLabelId(SpeedUnitList, fkSpeedUnit, 'pkSpeedUnit', 'speedUnitName') : "",
                defaultSpeed: fkDesignSpeedUnit || designSpeed ? true : false,
                availableTransferDate: availableTransferDate ? availableTransferDate.indexOf('Z') === -1 ? availableTransferDate + 'Z' : availableTransferDate : '',
                isTransferred: availableTransferDate !== null ? true : false,
                inSilentStoreSince: inSilentStoreSince ? inSilentStoreSince.indexOf('Z') === -1 ? inSilentStoreSince + 'Z' : inSilentStoreSince : '',
                netBookValueDate: netBookValueDate ? netBookValueDate.indexOf('Z') === -1 ? netBookValueDate + 'Z' : netBookValueDate : '',
            });
        }
    }, [equipmentWithId]);

    useEffect(() => {
        if(FLocationList.length){
          setflList(FLocationList);
        }
        if (samid && equipmentWithId && FLocationList.length && flag === 0) {
            const { fkFunctionalLocation
            } = equipmentWithId;
            setFlag(1);
            setServerFl(fkFunctionalLocation);
            setFormData({
                ...formData,
                fkFunctionalLocation: fkFunctionalLocation ? getLabelId(FLocationList, fkFunctionalLocation, 'pkFunctionalLocation', 'functionalLocationName') : "",
            });
        }
    }, [FLocationList]);

    useEffect(() => {
        if (samid && equipmentWithId && parentEquipmentList.length) {
            const { exParent, fkEquipment
            } = equipmentWithId;
            setParent(exParent);
            setTempEqParent(fkEquipment);
            const abc = fkEquipment ? getLabelId(parentEquipmentList, fkEquipment, 'pkEquipment', 'possibleParent') : "";
            setFormData({
                ...formData,
                fkEquipment: fkEquipment ? getLabelId(parentEquipmentList, fkEquipment, 'pkEquipment', 'possibleParent') : "",
            });
        }
    }, [parentEquipmentList]);

    const getLabel = (ex) => {
        const abdt = ex ? parentEquipmentList.find((a) => a['pkEquipment'] === ex) : "";
        return abdt ? abdt.possibleParent : 'N/A';
    }

    const getCondition = () => {
        if (samid && formData.fkStatus?.id === 4) {
            return true
        }
        if (formData.fkStatus?.id === 2) {
            return false
        }
        if (formData.isTransferred) {
            return false
        }
        return true;
    }

    const onAddSubmit = () =>{
       setExit(true);
       onSubmit()
    }

    const onSubmit = () => {
        setSubmit(true);
        const { fkStatus, isTransferred, availableTransferDate, fkCondition, fkCompany, inSilentStoreSince,
            fkArea, fkControlSystem, fkModel, fkFunction, fkFunctionalLocation, serialNumber, manufacturedYear, netBookValue } = formData;
        
        const netBookValueError = () => {
            if (netBookValue !== undefined && netBookValue !== "" && netBookValue !== null) {
                const nregex = /^[0-9\b]+$/;
                const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                if (/^[A-Za-z]*$/.test(netBookValue) || !nregex.test(netBookValue) || specialChars.test(netBookValue)){
                   return true;
                }
                else{
                    return false;
                }
            } 
            else
            {
                return false;
            }
        }
        const conditionError = () => {
            if (isTransferred) {
                return fkCondition && availableTransferDate ? false : true
            } else {
                return false
            }
        }
        if (conditionError() || netBookValueError() || (fkStatus?.id === 2 && !inSilentStoreSince) || !fkStatus || !fkCompany || !fkArea || !fkControlSystem || !fkModel || !fkFunction || !fkFunctionalLocation || !serialNumber || !manufacturedYear || (fkStatus?.id === 2 && !fkCondition)) {
            return;
        } else {
            const getExP = () => {
                if (samid) {
                    if (tempEqParent) {
                        const tv = tempEqParent !== formData.fkEquipment?.id ? tempEqParent : null;
                        setTempEqParent(null);
                        setPR(formData.fkEquipment?.id);
                        setParent(tempEqParent !== formData.fkEquipment?.id ? tempEqParent : null)
                        return tv;
                    } else {
                        setParent(pr !== formData.fkEquipment?.id ? pr: exParent);
                        return pr !== formData.fkEquipment?.id ? pr: exParent;
                    }
                } else {
                    return null;
                }
            }
            const payload = {
                "serialNumber": serialNumber,
                "manufacturedYear": manufacturedYear,
                "firstInstallationYear": formData.firstInstallationYear,
                "netBookValue": formData.netBookValue ? formData.netBookValue : null,
                "lastRebuildYear": formData.lastRebuildYear,
                "p1idNumber": formData.sapP1ref,
                "fkCompany": formData.fkCompany?.id,
                "fkFunctionalLocation": formData.fkFunctionalLocation?.id,
                "fkNetBookValueCurrency": formData.fkNetBookValueCurrency?.id,
                "fkStatus": formData.fkStatus?.id,
                "fkControlSystem": formData.fkControlSystem?.id,
                "fkCondition": formData.fkCondition?.id,
                "fkArea": formData.fkArea?.id,
                "fkModel": formData.fkModel?.id,
                "equipmentOemid": formData.equipmentOemid?.id,
                "fkSpeedUnit": formData.fkSpeedUnit?.id,
                "fkFunction": formData.fkFunction?.id,
                "fkDesignSpeedUnit": formData.fkDesignSpeedUnit?.id,
                "storageReasonComment": formData.storageReasonComment,
                "description": formData.description,
                "isConfidential": formData.isConfidential,
                "samComment": formData.samComment,
                "currentMaximumSpeed": formData.currentMaximumSpeed ? formData.currentMaximumSpeed : null,
                "comment": formData.comment,
                "isArchived": formData.isArchived,
                "designSpeed": formData.designSpeed || undefined,
                "exFunctionalLocation": formData.exFunctionalLocation,
                "netBookValueDate": formData.netBookValueDate ? formData.netBookValueDate : null,
                "availableTransferDate": formData.isTransferred ? formData.availableTransferDate : null,
                "exParent": getExP(),
                "fkEquipment": formData.fkEquipment?.id,
                "sapP1ref": formData.sapP1ref,
                "inSilentStoreSince": formData.fkStatus?.id === 2 ? formData.inSilentStoreSince : undefined
            };
            setTempEqParent(formData.fkEquipment?.id)
            if (samid) {
                let payLoadObj = {
                    "id":samid,
                    "FkNewFunctionalLocation": formData.fkFunctionalLocation?.id,
                    "FkNewStatus": formData.fkStatus?.id,
                    "FkNewFunction": formData.fkFunction?.id
                }
                setEditPayload({payload})
                dispatch(checkFunctionLocationAction(payLoadObj, samid, payload));
                setSubmit(false)        
            } else {
                dispatch(createEQAction(payload));
                setSubmit(false);
            }
        }
    }
    useEffect(() => {
        if(isShowFunctionalLocationAlert){
            setToggleFlModel({open: true,payload: editPayload })
        }
        
    }, [isShowFunctionalLocationAlert])
    
    
    const deleteConfirm = () => {
        toggleDeleteModal({});
        dispatch(deleteEQAction(samid))
        setView('main')
    }
    const onDeleteClick = (e, data) => {
        toggleDeleteModal({ open: true, data });
    }

    const { subProcessListNew:subProcessList } = useSelector((state) => state.subProcess);

    const getModel = () =>{
        if(!formData.fkArea){
            return [];
        }
        const newProcess = processList.filter((a)=> a.fkArea === formData.fkArea.id);
        const newSubpr =  subProcessList.filter((a)=> {
            return newProcess.find((b)=> b.pkProcess === a.fkProcess)
        })

        let fnData =  activeFunctionList.filter((a)=> {
            return newSubpr.find((b)=> b.pkSubProcess === a.fkSubProcess)
        })
        let newModelList = modelFunctionList.filter((a)=>{ 
            return fnData.find(({ pkFunction }) => {
              return  pkFunction === a.fkFunction
          }) ? true : false
      })

      let newModel = modelListNoD.filter((a)=>{
          return newModelList.find(({ fkModel }) => {
              return  fkModel === a.pkModel
          }) ? true : false
       })
    //     let newModel = modelListNoD.filter((a) => {
            
    //         let r = modelFunctionList.find(({ fkModel }) => {
    //             return fkModel === a.pkModel
    //         })

    //         let q = fnData.find(({ pkFunction }) => {
    //             return r && pkFunction === r.fkFunction
    //         })

    //       return q ? true : false
    //     });
        return newModel;
    }

    const getOemData = () => {
        if(!formData.fkArea){
            return [];
        }
        const newProcess = processList.filter((a)=> a.fkArea === formData.fkArea?.id);
        const newSubpr =  subProcessList.filter((a)=> {
            return newProcess.find((b)=> b.pkProcess === a.fkProcess)
        })

        let fnData =  activeFunctionList.filter((a)=> {
            return newSubpr.find((b)=> b.pkSubProcess === a.fkSubProcess)
        })

        let newModelList = modelFunctionList.filter((a)=>{ 
              return fnData.find(({ pkFunction }) => {
                return  pkFunction === a.fkFunction
            }) ? true : false
        })

        let newModel = modelListNoD.filter((a)=>{
            return newModelList.find(({ fkModel }) => {
                return  fkModel === a.pkModel
            }) ? true : false
        })

        // let newModel = modelListNoD.filter((a) => {
        //     let r = modelFunctionList.find(({ fkModel }) => {
        //         return fkModel === a.pkModel
        //     });

        //     let q = fnData.find(({ pkFunction }) => {
        //         return r && pkFunction === r.fkFunction
        //     })

        //   return q ? true : false

        // });
        return oemList.filter((a)=> newModel.find(({ fkOem }) => {
            return fkOem === a.pkOem
        }) ? true : false )
    }


    const getFunctionData = () => {
        let fnData = [...activeFunctionList];
        const tmpData = formData.fkModel;
        if (tmpData) {
            let tmp = modelFunctionList.filter((a) => {
                return tmpData.id === a.fkModel;
            });
        const newProcess = processList.filter((a)=> a.fkArea === formData.fkArea.id);
        const newSubpr =  subProcessList.filter((a)=> {
            return newProcess.find((b)=> b.pkProcess === a.fkProcess)
        })

        fnData =  fnData.filter((a)=> {
            return newSubpr.find((b)=> b.pkSubProcess === a.fkSubProcess)
        })
        fnData =  fnData.map((a)=> {
            let ab = newSubpr.find((b)=> b.pkSubProcess === a.fkSubProcess);
            let abc = processList.find(({pkProcess})=> pkProcess === ab.fkProcess)
            return {...a, sub: abc.processName + ' : ' + ab.subProcessName}
        })
    
        fnData = fnData.filter((a) => {
                return tmp.find(({ fkFunction }) => {
                    return fkFunction === a.pkFunction
                }) ? true : false
            })
        }
        return fnData;
    } 


    const okConfirm = (dt, flag)=>{
        setToggleFlModel({});
        if(flag === 'ok'){
            dispatch(updateEQAction(samid, {...dt.payload, flWarning: 1 }));
            dispatch({ type: "CLEAR_FNLOC_DATA" });
        }else{
            dispatch(updateEQAction(samid, {...dt.payload, flWarning: 2 }));
            dispatch({ type: "CLEAR_FNLOC_DATA" });
        }
        setSubmit(false);
    }

    const handleYesClose =()=>{
        if(formData?.fkModel?.id){
         const pew = modelListNoD.find(({pkModel})=>pkModel === formData?.fkModel?.id );
         const spdnm = SpeedUnitList.find(({ pkSpeedUnit }) => ( pew.fkSpeedUnit === pkSpeedUnit))
        setFormData({ ...formData, defaultSpeed: false, designSpeed: pew.designSpeed || '', fkDesignSpeedUnit: spdnm ? { id: spdnm.pkSpeedUnit, label: spdnm.speedUnitName } : undefined});
        }else{
            setFormData({ ...formData, defaultSpeed: false });
        }
        setSpeedModelOpen(false);
    }
    const handleNoClose =()=>{
        setFormData({ ...formData, defaultSpeed: true });
        setSpeedModelOpen(false);
    }

    const onDefaultSpeedChange = (e) =>{
        if(e.target.checked){
            setFormData({ ...formData, defaultSpeed: e.target.checked });
        }else{
            setSpeedModelOpen(true);
    // setFormData({ ...formData, defaultSpeed: e.target.checked });
        }
    }



    return <>
        <DeleteModal modalData={openDeleteModal} deleteConfirm={deleteConfirm} toggleModal={() => toggleDeleteModal({})} />
        <FlModel modalData={toggleFlModel} okConfirm={(dt, flag)=>okConfirm(dt, flag)} /> 
        
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={speedModelOpen}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={speedModelOpen}>
                    <Box sx={style}>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Box> You are going to remove the custom design speed and reset its value to the default model's one
                                <br />
                                Are you sure you want to proceed?
                                 </Box>
                            <Grid container>
                                <Grid item pt={3} sm={12}>
                                    <Box display="flex" justifyContent="flex-end">

                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{ "marginRight": "1rem" }}
                                            onClick={() => handleYesClose()}
                                        >
                                            Ok
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{ "marginRight": "1rem" }}
                                            onClick={() => handleNoClose()}
                                        >
                                            Cancel
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
        {(getEqLoading || isAreaLoading || isOemLoading || isModelLoading ||
            isFunctionLoading || isFLLoading || isSULoading || controlSytemsloading ||
            isStatusLoading || conditionLoading || currencyLoading)
            ? <Box sx={{ width: '100%' }} >
                <LinearProgress />
            </Box> : ""}
        <>
            <Box sx={{ p: 3 }}>
             
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>General Information</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="fkCompany"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="fkCompany"
                            value={formData.fkCompany ? { id: formData.fkCompany.id, label: formData.fkCompany.label } : null}
                            options={companyforEquipmentList.map(({ pkCompany, longCompanyName }) => ({ id: pkCompany, label: longCompanyName }))}
                            onChange={(e, value) => { onInputChange({ target: { value, name: 'fkCompany' } }) }}
                            renderInput={(params) => <TextField
                                fullWidth
                                maxwidth="20px"
                                {...params}
                                helperText={isSubmit && !formData.fkCompany && "Please select company"}
                                error={isSubmit && !formData.fkCompany}
                                label={"Company"}
                            />
                            }
                        />
                    </Grid>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        {console.log("avinash", formData.fkFunctionalLocation )}
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="fkFunctionalLocation"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="fkFunctionalLocation"
                            disabled={!formData.fkCompany}
                            value={formData.fkFunctionalLocation ? { id: formData.fkFunctionalLocation.id, label: formData.fkFunctionalLocation.label } : null}
                            options={FlList.map(({ pkFunctionalLocation, functionalLocationName }) => ({ id: pkFunctionalLocation, label: functionalLocationName }))}
                            onChange={(e, value) => { onInputChange({ target: { value, name: 'fkFunctionalLocation' } }) }}
                            renderInput={(params) => <TextField
                                fullWidth
                                maxwidth="20px"
                                {...params}
                                helperText={isSubmit && !formData.fkFunctionalLocation && "Please select location"}
                                error={isSubmit && !formData.fkFunctionalLocation}
                                label={"Functional Location"}
                            />
                            }
                        />
                    </Grid>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="fkArea"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="fkArea"
                            value={formData.fkArea ? { id: formData.fkArea.id, label: formData.fkArea.label } : null}
                            options={areaList.map(({ pkArea, areaName }) => ({ id: pkArea, label: areaName }))}
                            onChange={(e, value) => { onInputChange({ target: { value, name: 'fkArea' } }) }}
                            renderInput={(params) => <TextField
                                fullWidth
                                maxwidth="20px"
                                {...params}
                                helperText={isSubmit && !formData.fkArea && "Please select area"}
                                error={isSubmit && !formData.fkArea}
                                label={"Area"}
                            />
                            }
                        />
                    </Grid>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="Oem"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="equipmentOemid"
                            disabled={!formData.fkArea}
                            value={formData.equipmentOemid ? { id: formData.equipmentOemid.id, label: formData.equipmentOemid.label } : null}
                            options={getOemData().map(({ pkOem, oemName }) => ({ id: pkOem, label: oemName }))}
                            onChange={(e, value) => { onInputChange({ target: { value, name: 'equipmentOemid' } }) }}
                            renderInput={(params) => <TextField
                                fullWidth
                                maxwidth="20px"
                                {...params}
                                label={"OEM"}
                            />
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 3 }}>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="fkModel"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="fkModel"
                            value={formData.fkModel ? { id: formData.fkModel.id, label: formData.fkModel.label } : null}
                            options={getModel().filter(({ fkOem }) => fkOem === formData.equipmentOemid?.id).map(({ pkModel, modelName }) => ({ id: pkModel, label: modelName }))}
                            onChange={(e, value) => { onInputModelChange({ target: { value, name: 'fkModel' } }) }}
                            renderInput={(params) => <TextField
                                fullWidth
                                maxwidth="20px"
                                {...params}
                                helperText={isSubmit && !formData.fkModel && "Please select Model"}
                                error={isSubmit && !formData.fkModel}
                                label={"Model"}
                            />
                            }
                        />
                    </Grid>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="Function"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="Function"
                            groupBy={(option) => option.sub}
                            disabled={!formData.fkModel}
                            value={formData.fkFunction ? { id: formData.fkFunction.id, label: formData.fkFunction.label } : null}
                            options={getFunctionData().map(({ pkFunction, functionName, sub }) => ({sub, id: pkFunction, label: functionName }))}
                            onChange={(e, value) => { onInputChange({ target: { value, name: 'fkFunction' } }) }}
                            renderInput={(params) => <TextField
                                fullWidth
                                maxwidth="20px"
                                {...params}
                                helperText={isSubmit && !formData.fkFunction && "Please select function"}
                                error={isSubmit && !formData.fkFunction}
                                label={"Function"}
                            />
                            }
                        />
                    </Grid>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <TextField
                            id={'srno'}
                            fullWidth
                            size="small"
                            InputLabelProps={{ shrink: formData.serialNumber ? true : false }}
                            label='Serial Number'
                            name='serialNumber'
                            value={formData.serialNumber}
                            inputProps={{ maxLength: 20 }}
                            onChange={onInputChange}
                            helperText={isSubmit && !formData.serialNumber && "Please enter Serial number"}
                            error={isSubmit && !formData.serialNumber}
                        />
                    </Grid>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <TextField
                            id='sapP1ref'
                            fullWidth
                            size="small"
                            label='SAP/P1 Ref'
                            InputLabelProps={{ shrink: formData.sapP1ref ? true : false }}
                            name='sapP1ref'
                            value={formData.sapP1ref}
                            inputProps={{ maxLength: 20 }}
                            onChange={onInputChange}
                        />
                    </Grid>
                    <Grid sx={{ pl: 1, mt: '20px' }} item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            disabled={!parentEquipmentList.filter(({ possibleParent }) => possibleParent).map(({ possibleParent, pkEquipment }) => ({ id: pkEquipment, label: possibleParent })).length}
                            id="fkEquipment"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="fkEquipment"
                            value={formData.fkEquipment ? { id: formData.fkEquipment.id, label: formData.fkEquipment.label } : null}
                            options={parentEquipmentList.filter(({ possibleParent }) => possibleParent).map(({ pkEquipment, possibleParent }) => ({ id: pkEquipment, label: possibleParent }))}
                            onChange={(e, value) => { onParentChange({ target: { value, name: 'fkEquipment' } }) }}
                            renderInput={(params) => <TextField
                                fullWidth
                                maxwidth="20px"
                                {...params}
                                label={"Parent Equipment"}
                            />
                            }
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2.5, borderBottomWidth: 2 }} />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <Box sx={{ fontSize: "12px" }}>Ex active functional location</Box>
                        <Box>{formData.exFunctionalLocation ? formData.exFunctionalLocation : 'N/A'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Box sx={{ fontSize: "12px" }}>Ex parent</Box>
                        <Box>{exParent ? getLabel(exParent) : "N/A"}</Box>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2.5, borderBottomWidth: 2 }} />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            InputLabelProps={{ shrink: formData.description ? true : false }}
                            value={formData.description}
                            name={'description'}
                            onChange={onInputChange}
                            rows={4}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2.5, borderBottomWidth: 2 }} />
                <Grid container spacing={1}>
                    <Grid sx={{ pl: 1 }} xs={12} sm={4}>
                        <Grid sx={{ p: '0 !important' }} container xs={12} sm={12}>
                            <Grid sx={{ pt: 1 }} xs={6} sm={4}>
                                <Box sx={{ fontWeight: 600, }}>Default speed</Box>
                            </Grid>
                            <Grid sx={{ pl: 1 }} item xs={6} sm={6}>
                                <FormControlLabel
                                    value="true"
                                    control={<Checkbox name="defaultSpeed"
                                        checked={formData.defaultSpeed ? true : false}
                                        onChange={onDefaultSpeedChange} />}
                                    label="Custom"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                        {formData.defaultSpeed ?  (<Grid container item xs={12} sm={12}>
                            <Grid item xs={4} sm={4}>
                                <TextField
                                    onChange={onInputChange}
                                    InputLabelProps={{ shrink: formData.designSpeed ? true : false }}
                                    value={formData.designSpeed}
                                    name="designSpeed" size='small' />
                            </Grid>
                            <Grid item sx={{ pl: 1 }} xs={8} sm={8}>
                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    fullWidth
                                    onChange={(e, value) => { onInputChange({ target: { value, name: 'fkDesignSpeedUnit' } }) }}
                                    id="packs-select"
                                    name='fkDesignSpeedUnit'
                                    value={formData.fkDesignSpeedUnit ? { id: formData.fkDesignSpeedUnit.id, label: formData.fkDesignSpeedUnit.label } : null}
                                    options={SpeedUnitList.map(({ pkSpeedUnit, speedUnitName }) => ({ id: pkSpeedUnit, label: speedUnitName }))}
                                    renderInput={(params) => <TextField label="unit" {...params} />}
                                />
                            </Grid>
                        </Grid>) : <Grid>{formData.designSpeed ? formData.designSpeed +" "+ formData.fkDesignSpeedUnit.label: 'N/A'}</Grid>}
                    </Grid>
                    <Grid sx={{ pl: 1 }} xs={12} sm={4}>
                        <Grid sx={{ pt: 1, height: '42px' }} container xs={12} sm={12}>
                            <Box sx={{ fontWeight: 600, }}>Production speed</Box>
                        </Grid>
                        <Grid container item xs={12} sm={12}>
                            <Grid item xs={4} sm={4}>
                                <TextField value={formData.currentMaximumSpeed} onChange={onInputChange} name="currentMaximumSpeed" size='small' />
                            </Grid>
                            <Grid item sx={{ pl: 1 }} xs={8} sm={8}>
                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    fullWidth
                                    id="packs-select"
                                    name='fkSpeedUnit'
                                    onChange={(e, value) => { onInputChange({ target: { value, name: 'fkSpeedUnit' } }) }}
                                    value={formData.fkSpeedUnit ? { id: formData.fkSpeedUnit.id, label: formData.fkSpeedUnit.label } : null}
                                    options={SpeedUnitList.map(({ pkSpeedUnit, speedUnitName }) => ({ id: pkSpeedUnit, label: speedUnitName }))}
                                    renderInput={(params) => <TextField label='unit' {...params} />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{ pl: 1 }} xs={12} sm={4}>
                        <Grid container sx={{ pt: 1, height: '42px' }} xs={12} sm={12}>
                            <Box sx={{ fontWeight: 600, }}>Control system</Box>
                        </Grid>
                        <Grid container item xs={12} sm={12}>
                            <Autocomplete
                                disablePortal
                                size="small"
                                fullWidth
                                id="category-select"
                                name='fkControlSystem'
                                onChange={(e, value) => { onInputChange({ target: { value, name: 'fkControlSystem' } }) }}
                                value={formData.fkControlSystem ? { id: formData.fkControlSystem.id, label: formData.fkControlSystem.label } : null}
                                controlSytems
                                options={controlSytems.map(({ pkControlSystem, controlSystemName }) => ({ id: pkControlSystem, label: controlSystemName }))}
                                renderInput={(params) => <TextField
                                    helperText={isSubmit && !formData.fkControlSystem && "Please enter control system"}
                                    error={isSubmit && !formData.fkControlSystem}
                                    {...params} />}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid sx={{ pt: "16px !important" }} item xs={12} sm={4}>
                        <TextField fullWidth name="manufacturedYear" onChange={onInputChange}
                            label="Manufactured year"
                            inputProps={{ maxLength: 4 }}
                            InputLabelProps={{ shrink: formData.manufacturedYear ? true : false }}
                            value={formData.manufacturedYear} size='small'
                            helperText={isSubmit && !formData.manufacturedYear && "Please enter manufactured year"}
                            error={isSubmit && !formData.manufacturedYear}
                        />
                    </Grid>
                    <Grid sx={{ pt: "16px !important" }} item xs={12} sm={4}>
                        <TextField
                            name="firstInstallationYear" onChange={onInputChange}
                            fullWidth
                            InputLabelProps={{ shrink: formData.firstInstallationYear ? true : false }}
                            inputProps={{ maxLength: 4 }}
                            label="First installation year"
                            value={formData.firstInstallationYear} size='small'
                        />
                    </Grid>
                    <Grid sx={{ pt: "16px !important" }} item xs={12} sm={4}>
                        <TextField
                            name="lastRebuildYear" onChange={onInputChange}
                            fullWidth label="Last rebuid year"
                            InputLabelProps={{ shrink: formData.lastRebuildYear ? true : false }}
                            inputProps={{ maxLength: 4 }}
                            value={formData.lastRebuildYear} size='small'
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2.5, borderBottomWidth: 2 }} />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: 1 }}>Equipment status information</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ mt: 1, mb: 4 }}>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            fullWidth
                            id="category-select"
                            disabled={samid && formData.fkStatus?.id === 4}
                            name='fkStatus'
                            options={statusList.map(({ pkStatus, statusName }) => ({ id: pkStatus, label: statusName }))}
                            onChange={(e, value) => {
                                onInputChange({ target: { value, name: 'fkStatus' } })
                            }}
                            value={formData.fkStatus ? { id: formData.fkStatus.id, label: formData.fkStatus.label } : null}
                            renderInput={(params) => <TextField
                                helperText={isSubmit && !formData.fkStatus && "Please select status"}
                                error={isSubmit && !formData.fkStatus}
                                label="Status" {...params} />}
                        />
                    </Grid>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="In silent store since"
                                inputFormat="MM/DD/YYYY"
                                emptyLabel="Select date"
                                size="small"
                                disabled={formData.fkStatus?.id !== 2}
                                fullWidth
                                value={(formData.inSilentStoreSince === null || formData.inSilentStoreSince === '' || formData.inSilentStoreSince === undefined) ? null : dayjs(formData.inSilentStoreSince)}
                                onChange={(value) => onInputChange({ target: { name: 'inSilentStoreSince', value } })}
                                renderInput={(params) => <TextField
                                    sx={isSubmit && !formData.inSilentStoreSince && formData.fkStatus?.id === 2 ? {
                                        "& .Mui-error": {
                                            color: '#d32f2f'
                                        },
                                        "& .MuiFormHelperText-root": {
                                            color: '#d32f2f'
                                        },
                                        "& .css-ye9ku6-MuiFormLabel-root-MuiInputLabel-root": {
                                            color: '#d32f2f'
                                        },
                                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#d32f2f"
                                        }
                                    } : {
                                        "& .css-ye9ku6-MuiFormLabel-root-MuiInputLabel-root": {
                                            color: '#808080'
                                        },
                                    }}
                                    helperText={isSubmit && !formData.inSilentStoreSince && formData.fkStatus?.id === 2 && "Required"}
                                    error={isSubmit && !formData.inSilentStoreSince && formData.fkStatus?.id === 2}
                                    fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid sx={{ pr: 1 }} item xs={12} sm={3}>
                        <FormControlLabel
                            value=""
                            control={<Checkbox name='isTransferred'
                                onChange={(e) => setFormData({ ...formData, isTransferred: e.target.checked })}
                                disabled={samid && formData.fkStatus?.id === 4}
                                value={formData.isTransferred ? true : false}
                                checked={formData.isTransferred ? true : false} />}
                            label="Available for transfer"
                            labelPlacement="end"
                        />
                    </Grid>

                    {formData.isTransferred && <Grid sx={{ pl: 1 }} item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Available for transfer date"
                                inputFormat="MM/DD/YYYY"
                                size="small"
                                fullWidth
                                emptyLabel="Select date"
                                value={(formData.availableTransferDate === null || formData.availableTransferDate === '' || formData.availableTransferDate === undefined) ? null : dayjs(formData.availableTransferDate)}
                                onChange={(value) => onInputChange({ target: { name: 'availableTransferDate', value } })}
                                renderInput={(params) => <TextField fullWidth
                                    sx={isSubmit && !formData.availableTransferDate && formData.isTransferred ? {
                                        "& .Mui-error": {
                                            color: '#d32f2f'
                                        },
                                        "& .MuiFormHelperText-root": {
                                            color: '#d32f2f'
                                        },
                                        "& .css-ye9ku6-MuiFormLabel-root-MuiInputLabel-root": {
                                            color: '#d32f2f'
                                        },
                                        "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#d32f2f"
                                        }
                                    } : {
                                        "& .css-ye9ku6-MuiFormLabel-root-MuiInputLabel-root": {
                                            color: '#808080'
                                        },
                                    }}
                                    error={isSubmit && !formData.availableTransferDate && formData.isTransferred}
                                    helperText={isSubmit && !formData.availableTransferDate && formData.isTransferred && "Please select condition"}
                                    size="small" {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>}
                </Grid>
                <Grid container spacing={2}>
                    <Grid sx={{ pl: 1 }} item xs={12} sm={9}>
                        <TextField
                            id="Storage_reason_comment-static"
                            label="Storage reason comment"
                            multiline
                            InputLabelProps={{ shrink: formData.storageReasonComment ? true : false }}
                            rows={4}
                            value={formData.storageReasonComment}
                            name="storageReasonComment"
                            onChange={onInputChange}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid sx={{ pl: '8px !important', ml: 0, pt: "35px" }} item xs={12} sm={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            fullWidth
                            disabled={getCondition()}
                            id="fkCondition"
                            onChange={(e, value) => onInputChange({ target: { name: 'fkCondition', value } })}
                            name='fkCondition'
                            value={formData.fkCondition ? { id: formData.fkCondition.id, label: formData.fkCondition.label } : null}
                            options={conditions.map(({ pkCondition, conditionName }) => ({ id: pkCondition, label: conditionName }))}
                            renderInput={(params) => <TextField
                                error={isSubmit && !formData.fkCondition && (formData.isTransferred || formData.fkStatus?.id ===2)}
                                helperText={isSubmit && !formData.fkCondition && (formData.isTransferred || formData.fkStatus?.id ===2) && "Please select condition"}
                                label="Condition" {...params} />}
                        />
                    </Grid>
                    <Grid sx={{ pt: 2, pl: 2, height: '42px', mt: 2, mb: 2 }} container xs={12} sm={12}>
                        <Box sx={{ fontWeight: 600, }}>Net book value</Box>
                    </Grid>
                    <Grid sx={{ pl: 2 }} xs={12} sm={12}>
                        <Grid container item xs={12} sm={12}>
                            <Grid item xs={6} sm={4}>
                                <TextField
                                    id="nbValue-static"
                                    name="netBookValue"
                                    size="small"
                                    InputLabelProps={{ shrink: formData.netBookValue ? true : false }}
                                    value={formData.netBookValue}
                                    onChange={onInputChange}
                                    sx={{ width: '100%' }}
                                    pattern="[0-9]*"
                                    variant="outlined"
                                    helperText={isSubmit && formData.netBookValue && typeof(formData.netBookValue) !== 'number' && "Please rectify the format of Net book value. Example: valid format : 123 invalid format : 123.00 or 1,23"}
                                    error={isSubmit && formData.netBookValue && typeof(formData.netBookValue) !== 'number'}
                                />
                            </Grid>
                            <Grid item sx={{ pl: 1 }} xs={6} sm={4}>
                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    id="packs-select"
                                    name='fkNetBookValueCurrency'
                                    onChange={(e, value) => onInputChange({ target: { name: 'fkNetBookValueCurrency', value } })}
                                    value={formData.fkNetBookValueCurrency ? { id: formData.fkNetBookValueCurrency.id, label: formData.fkNetBookValueCurrency.label } : null}
                                    options={currencies.map(({ pkCurrency, currencyName }) => ({ id: pkCurrency, label: currencyName }))}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Grid>
                            <Grid item sx={{ pl: 1 }} xs={6} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Net book value date"
                                        inputFormat="MM/DD/YYYY"
                                        size="small"
                                        fullWidth
                                        emptyLabel="Net book value date"
                                        value={(formData.netBookValueDate === null || formData.netBookValueDate === '' || formData.netBookValueDate === undefined) ? null : dayjs(formData.netBookValueDate)}
                                        onChange={(value) => onInputChange({ target: { name: 'netBookValueDate', value } })}
                                        renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="Storage_reason_comment-static"
                            label="Comment"
                            name="comment"
                            multiline
                            InputLabelProps={{ shrink: formData.comment ? true : false }}
                            value={formData.comment}
                            onChange={onInputChange}
                            rows={4}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid container item xs={12} sm={4}>
                    <FormControlLabel
                        value="true"
                        control={<Checkbox
                            onChange={(e) => setFormData({ ...formData, isConfidential: e.target.checked })}
                            value={formData.isConfidential}
                            checked={formData.isConfidential ? true : false}
                            name="isConfidential"
                        />}
                        label="Is confidential"
                        labelPlacement="end"
                    />
                </Grid>
                <Grid sx={{ pt: 2 }} container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="Storage_reason_comment-static"
                            label="SAM comment"
                            multiline
                            onChange={onInputChange}
                            name="samComment"
                            InputLabelProps={{ shrink: formData.samComment ? true : false }}
                            value={formData.samComment}
                            rows={4}
                            sx={{ width: '100%' }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Grid container>
                <Grid item p={3} sm={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button sx={{ mr: 1 }} onClick={() => setView('main')} variant="outlined">Cancel</Button>
                           {showOnlyOneSave ? '' : <Button onClick={onSubmit} disabled={(samid && equipmentWithId.fkStatus === 7) || addEqLoading} variant="contained">Save</Button>}
                            {samid  ? '' : <Button sx={{ mr: 1, ml: 1 }} onClick={onAddSubmit} disabled={(samid && equipmentWithId.fkStatus === 7) || addEqLoading} variant="contained">Save and Add</Button>}
                    
                        {(isGEAdmin && samid) ? <Button disabled={samid && equipmentWithId.fkStatus === 7} sx={{ ml: 1 }} onClick={onDeleteClick} variant="outlined">Delete</Button> : ""}
                    </Box>
                </Grid>
            </Grid>
        </>
    </>
}
